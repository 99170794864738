import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { createValidationRules } from "../../../../utils/validationUtils";

const locale = require("react-redux-i18n").I18n;
function SelectDocementType({
  dataValidation,
  form,
  isValidationData,
  onChangeDoc,
  onNextStepVerify,
}) {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const profile = user?.profile;

  const validatorID = (regxpattern, validation) => ({
    validator(rule, value) {
      const regex = new RegExp(regxpattern);

      if (value.match(regex)) {
        return Promise.resolve();
      } else if (value === "" || value === undefined) {
        return Promise.resolve();
      } else {
        return Promise.reject(validation);
      }
    },
  });
  return (
    <div className="d-flex justify-content-center flex-column align-items-center mt-4">
      <div className="verify-w-input">
        {dataValidation.map((e, i) => (
          <div className="d-flex" style={{ height: "auto" }} key={i}>
            <div className="my-auto">
              <div
                className={`cursor-pointer iux-radio ${
                  e.document_type === isValidationData.document_type &&
                  "checked"
                } `}
                onClick={async () => {
                  form.resetFields();
                  onChangeDoc(e);
                  await form.setFieldsValue({
                    document_type: e.document_type,
                  });
                }}
              >
                <div></div>
              </div>
            </div>
            <div className="w-100 ml-2">
              <Form.Item
                name={`id_card_number_${e.document_type}`}
                rules={createValidationRules(e, locale)}
                label={locale.t(`documentType.${e?.document_type}`)}
              >
                <Input
                  key={i}
                  disabled={
                    e?.document_type !== isValidationData?.document_type
                  }
                  maxLength={profile?.country_id === 2 ? 13 : 99}
                  placeholder={locale.t(`documentType.${e.document_type}`)}
                  autocomplete="off"
                  onChange={(e) =>
                    form.setFieldsValue({ id_card_number: e.target.value })
                  }
                  name={`id_card_number_${e.document_type}`}
                  onKeyPress={(event) => {
                    if (!/[a-zA-Z0-9.]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    event.preventDefault();
                  }}
                />
              </Form.Item>
            </div>
          </div>
        ))}
      </div>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            disabled={
              !form.isFieldTouched(
                `id_card_number_${isValidationData.document_type}`
              ) ||
              form.getFieldValue(
                `id_card_number_${isValidationData.document_type}`
              ) === "" ||
              form.getFieldsError().filter(({ errors }) => errors.length).length
            }
            // type="submit"
            loading={loading}
            onClick={() => onNextStepVerify(true)}
            className="btn-primary-new-iux"
          >
            {locale.t("continue")}
          </Button>
        )}
      </Form.Item>
    </div>
  );
}

export default SelectDocementType;
