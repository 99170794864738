import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Divider,
  Button,
  Form,
  Input,
  Tooltip,
  message,
  Row,
  Col,
  Badge,
  Select,
} from "antd";
import { get, post } from "../../../../helper/request";

import PaymentCard from "../PaymentCard";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { ClipLoader } from "react-spinners";
import { InfoCircleOutlined } from "@ant-design/icons";
import { fCurrencySymbol } from "../../../../helper/formatNumber";
import CryptoRecive from "./CryptoRecive";
import ModalScanQr from "./ModalScanQr";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;
function WithDrawSecondStep(props) {
  const {
    paymentId,
    paymentKey,
    // account: { account_number },
    // payment_config: { minimum_deposit, maximum_deposit },
    payment_name,
  } = props;
  const [account, setAccount] = useState("");
  const accountlist = useSelector(({ account }) => account.accountTypeList);
  const accountDetail = accountlist?.find(
    (e, i) => e.name === account && e.type === "real"
  );
  const [form] = Form.useForm();
  const [minimum, setMinimum] = useState("");
  const [amount, setAmount] = useState(0);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [rateCrypto, setRateCrypto] = useState(0);
  const [feeCrypto, setFeeCrypto] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dataCrypto, setDataCrypto] = useState(null);
  // const [message, setMessage] = useState("");\
  const [openModalScan, setOpenModalScan] = useState(false);
  const [laos, setLaos] = useState(null);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const bookBankVerify = useSelector((state) => state.user.bookBankVerify);
  useEffect(() => {
    setLaos(props?.user?.profile?.country_id);
    setAccount(props.account.account_type);
  }, []);
  useEffect(() => {
    // setLoading(true);
    if (paymentKey === "b2binpay") {
      fecthCryptoWithdraw();
    } else {
      setLoading(false);
    }
  }, [paymentKey]);
  useEffect(() => {
    setLoading(true);
    if (account && paymentKey !== "b2binpay") {
      post(`${renderApiDomain()}payment/rate`, {
        payment_id: paymentId,
        type: "withdraw",
      })
        .then(({ data }) => {
          setRate(data.data.rate);
          props.onSetRate(data.data.rate);
          let miniAcc = accountDetail.minimum_withdraw;
          let miniPayment =
            props.paymentSelected?.payment_config[0]?.minimum_withdraw /
            data.data.rate;
          let isCondition = miniAcc > miniPayment ? miniAcc : miniPayment;
          let checkCent =
            props.account.account_type === "Cent"
              ? isCondition * 100
              : isCondition;
          let urlParams = new URLSearchParams(window.location.search);
          let account_number = urlParams.get("account");
          let condition = urlParams.get("condition");
          let isamount = urlParams.get("amount");
          if (isamount) {
            form.setFieldsValue({
              amount: Math.ceil(isamount),
            });
            setAmount(Math.ceil(isamount));
            props.onChangeAmount(Math.ceil(isamount));
          } else {
            form.setFieldsValue({
              amount: Math.ceil(checkCent),
            });
            setAmount(Math.ceil(checkCent));
            props.onChangeAmount(Math.ceil(checkCent));
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [account]);

  function checkWithDraw(rule, value) {
    const mm = props.paymentSelected?.payment_config[0]?.minimum_withdraw;
    const maxi = props.paymentSelected.payment_config[0].maximum_withdraw;

    const current_balance = props.account.current_balance;

    if (
      props.account.account_type !== "Cent" &&
      current_balance > 10000 &&
      value > 10000
    ) {
      return Promise.reject(
        `${locale.t("withdraw_error.t_max")}  
         ${parseInt(10000)
           .toFixed(2)
           .replace(/\d(?=(\d{3})+\.)/g, "$&,")} USD`
      );
    } else {
      if (props.account.account_type === "Cent") {
        if (value > accountDetail?.maximum_withdraw) {
          return Promise.reject(
            `${locale.t(
              "withdraw_error.t_max"
            )} ${accountDetail?.maximum_withdraw.toLocaleString()} USC`
          );
        } else if (value > current_balance) {
          return Promise.reject(locale.t("withdraw_error.t_error"));
        } else {
          if (value >= (mm / rate) * 100) {
            return Promise.resolve();
          }
          return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
        }
      } else if (value > accountDetail?.maximum_withdraw) {
        return Promise.reject(
          `${locale.t(
            "withdraw_error.t_max"
          )} $${(accountDetail?.maximum_withdraw).toLocaleString()}`
        );
      } else if (value > current_balance) {
        return Promise.reject(locale.t("withdraw_error.t_error"));
      } else {
        if (value >= mm / rate) {
          return Promise.resolve();
        }
        return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
      }
    }
  }
  function checkWithDrawStatus(rule, value) {
    const mm = props.paymentSelected?.payment_config[0]?.minimum_withdraw;
    const maxi = props.paymentSelected.payment_config[0].maximum_withdraw;

    const current_balance = props.account.current_balance;

    if (
      props.account.account_type !== "Cent" &&
      current_balance > 10000 &&
      value > 10000
    ) {
      return Promise.reject(
        `${locale.t("withdraw_error.t_max")}  
         ${parseInt(10000)
           .toFixed(2)
           .replace(/\d(?=(\d{3})+\.)/g, "$&,")} USD`
      );
    } else {
      if (props.account.account_type === "Cent") {
        if (value > accountDetail?.maximum_withdraw) {
          return Promise.reject(
            `${locale.t(
              "withdraw_error.t_max"
            )} ${accountDetail?.maximum_withdraw.toLocaleString()} USC`
          );
        } else if (value > current_balance) {
          return Promise.reject(locale.t("withdraw_error.t_error"));
        } else {
          if (value >= (mm / rate) * 100) {
            return Promise.resolve();
          }
          return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
        }
      } else if (value > accountDetail?.maximum_withdraw) {
        return Promise.reject(
          `${locale.t(
            "withdraw_error.t_max"
          )} $${(accountDetail?.maximum_withdraw).toLocaleString()}`
        );
      } else if (value > current_balance) {
        return Promise.reject(locale.t("withdraw_error.t_error"));
      } else {
        if (value >= mm / rate) {
          return Promise.resolve();
        }
        return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
      }
    }
  }
  function checkWithDrawAl(rule, value) {
    if (value > props.account.current_balance) {
      return Promise.reject(locale.t("withdraw_error.t_error"));
    } else {
      if (+value > accountDetail?.maximum_withdraw) {
        return Promise.reject(
          `${locale.t("withdraw_error.amount_crypto")} ${
            accountDetail?.maximum_withdraw
          } ${prefix}`
        );
      } else if (+value >= accountDetail?.minimum_withdraw) {
        if (+value / rate >= minimum) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            `${locale.t("withdraw_error.amount_crypto")} ${minimum} ${prefix}`
          );
        }
      } else {
        return Promise.reject(
          `${locale.t("withdraw_error.amount_crypto")} 3 USD`
        );
      }
    }
  }
  const checkWithDrawCrypto = (rule, value) => {
    const mm = props.paymentSelected?.payment_config[0]?.minimum_withdraw;
    const maxi = props.paymentSelected.payment_config[0].maximum_withdraw;

    const current_balance = props.account.current_balance;
    if (props.account.account_type === "Cent") {
      if (value > accountDetail?.maximum_withdraw) {
        return Promise.reject(
          `${locale.t(
            "withdraw_error.t_max"
          )} ${accountDetail?.maximum_withdraw.toLocaleString()} USC`
        );
      } else if (value > current_balance) {
        return Promise.reject(locale.t("withdraw_error.t_error"));
      } else {
        if (value >= mm * 100) {
          return Promise.resolve();
        }
        return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
      }
    } else if (value > accountDetail?.maximum_withdraw) {
      return Promise.reject(
        `${locale.t(
          "withdraw_error.t_max"
        )} $${(accountDetail?.maximum_withdraw).toLocaleString()}`
      );
    } else if (value > current_balance) {
      return Promise.reject(locale.t("withdraw_error.t_error"));
    } else {
      if (value - feeCrypto / rateCrypto >= mm) {
        return Promise.resolve();
      }
      return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
    }
  };
  const renderRateText = (i) => {
    if (i) {
      if (paymentKey === "alphapo") {
        return `${locale.t("withdraw_info.amount")} (${prefix})`;
      } else {
        if (laos === 8) {
          return "ຈຳ ນວນເງິນ (LAK)";
        } else {
          return <div>{` (${prefix})`}</div>;
        }
      }
    } else {
      if (paymentKey === "alphapo") {
        return `${locale.t(
          "withdraw_info.currency_rate"
        )}(${prefix}/${suffix})`;
      } else if (props.account.account_type === "Cent") {
        if (laos === 8) {
          return "ອັດຕາເງິນຕາ (LAK-> USD)";
        } else {
          return (
            <div>{`${locale.t(
              "withdraw_info.currency_rate"
            )}(USD/${prefix})`}</div>
          );
        }
      } else {
        if (laos === 8) {
          return "ອັດຕາເງິນຕາ (LAK-> USD)";
        } else {
          return (
            <div>{`${locale.t("withdraw_info.currency_rate")}(USD/${
              profileCountry?.symbol
            })`}</div>
          );
        }
      }
    }
  };
  const renderRateWithdraw = () => {
    if (paymentKey === "alphapo") {
      if (isNaN((props.amount / rate).toFixed(6))) return 0.0;
      else return (props.amount / rate).toFixed(6);
    } else if (props.account.account_type === "Cent") {
      return ` ${((props.amount / 100) * parseFloat(rate))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    } else {
      return ` ${(props.amount * parseFloat(rate).toFixed(2))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  };

  const onFinish = async (values) => {
    try {
      setButtonLoading(true);
      const payloadTH = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        method: props.bookBankVerify.bank_name,
        amount:
          props.account.account_type === "Cent"
            ? values.amount / 100
            : values.amount,
        amount_thb:
          props.account.account_type === "Cent"
            ? (values.amount / 100) * rate
            : values.amount * rate,
        transfer_account_number: props.bookBankVerify.bank_account_number,
        name: `${props.bookBankVerify.bank_account_name}`,
      };
      const payloadGate = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        method: props.bookBankVerify.bank_name,
        amount:
          props.account.account_type === "Cent"
            ? values.amount / 100
            : values.amount,
        amount_thb:
          props.account.account_type === "Cent"
            ? (values.amount / 100) * rate
            : values.amount * rate,
        transfer_account_number: props.bookBankVerify.bank_account_number,
        name: `${props.bookBankVerify.bank_account_name}`,
        wallet_address: values.wallet_address,
      };
      const payloadCrypto = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        amount: values.amount,
        address: values.address,
        currency: prefix,
      };
      const payloadBonus = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        amount: values.amount,
        currency: prefix,
        bonus_withdraw_condition_id: props?.accountWelcome?.condition || null,
      };
      const payloadB2binpay = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        amount: values.amount,
        wallet_address: values.wallet_address,
        currency_id: values.currency,
      };
      const bonusAndB2binpay = {
        payment_id: paymentId,
        account_trade_id: props.account.id,
        amount: values.amount,
        wallet_address: values.wallet_address,
        currency_id: values.currency,
        bonus_withdraw_condition_id: props?.accountWelcome?.condition || null,
      };

      const { data } = await post(
        renderApiDomain() + "user/account/initial-withdraw",
        props?.accountWelcome !== null && paymentKey === "b2binpay"
          ? bonusAndB2binpay
          : props?.accountWelcome !== null
          ? payloadBonus
          : paymentKey === "alphapo"
          ? payloadCrypto
          : paymentKey === "gcash_payout" ||
            paymentKey === "maya_payout" ||
            paymentKey === "gate_mobile_money_payout" ||
            paymentKey === "limepay_mobile_money_payout"
          ? payloadGate
          : paymentKey === "b2binpay"
          ? payloadB2binpay
          : payloadTH
      );
      setButtonLoading(false);
      props.onNextStep({
        account_withdraw_id: data.data.id,
        amount: values.amount,
        payment_id: paymentId,
        payment_key: paymentKey,
        address: values.address,
      });
    } catch (error) {
      props?.onBackStep(props?.accountWelcome && "welcome");
      if (error?.response?.data?.code === 3012) {
        message.error(
          error?.response?.data?.error + ". Please choose a new payment."
        );
        props?.onBackStep();
      } else if (error?.response?.data?.code === 3003) {
        message.error(error?.response?.data?.error + profileCountry.symbol);
      } else {
        message.error(error?.response?.data?.error);
      }

      setButtonLoading(false);
      console.log(error);
    }
  };
  const handleKeyPress = (event) => {
    const input = event.target.value + event.key;

    // Regular expression to check if the input is a two-digit number with optional decimal point
    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(input)) {
      event.preventDefault(); // Prevents entering the invalid key
    } else {
    }
  };
  const removeLeadingZeros = (value) => {
    return value.replace(/^0+/, "");
  };
  const checkAmount = () => {
    if (props.account.account_type === "Cent") {
      if (
        (props.amount / 100) * rate <
        props.paymentSelected?.payment_config[0]?.minimum_withdraw
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        props.amount * rate <
        props.paymentSelected?.payment_config[0]?.minimum_withdraw
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  const checkInsufficient = () => {
    const current_balance = props.account.current_balance;
    if (props.amount > current_balance) {
      return false;
    } else {
      return true;
    }
  };
  const checkAmountBroker = () => {
    if (paymentKey === "b2binpay") {
      if (
        props.amount - feeCrypto / rateCrypto >=
        accountDetail?.minimum_withdraw
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (props.account.account_type === "Cent") {
        if (props.amount < accountDetail?.minimum_withdraw * 100) {
          return false;
        } else {
          return true;
        }
      } else {
        if (props.amount < accountDetail?.minimum_withdraw) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const fecthCryptoWithdraw = async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${renderApiDomain() + `withdraw/methods/b2binpay`}`
      );
      setDataCrypto(data?.data);
      form.setFieldValue("currency", data?.data[0]?.currency_id);
      // fecthFeeCrypto(data?.data[0]?.currency_id);
      props.onChangeCryptoSeleted(data?.data[0]);
      fecthRateCrypto(data?.data[0]?.currency_id);
      // props.onChangeCryptoSeleted(data?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const fecthRateCrypto = async (value) => {
    try {
      const payload = {
        payment_id: paymentId,
        type: "withdraw",
        currency_id: value || form.getFieldValue("currency"),
      };
      const { data } = await post(
        `${renderApiDomain() + `payment/rate`}`,
        payload
      );
      props.onChangeCryptoRate(data.data.rate);
      setRateCrypto(data.data.rate);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checkCodeFeeCrypto = async (rule, value) => {
    if (value === null) {
      return false;
    } else {
      try {
        const payload = {
          wallet_address: value,
          amount: form.getFieldValue("amount") * rateCrypto,
          currency_id: form.getFieldValue("currency"),
        };
        const { data } = await post(
          `${renderApiDomain() + `payment/crypto/fee`}`,
          payload
        );
        props.onChangeCryptoFee(data.data.fee);
        setFeeCrypto(data.data.fee);
        return true;
      } catch (error) {
        return false;
        // Promise.reject(
        //   ` ${locale.t("withdraw_info.wallet_address_error")}`
        // );
      }
    }
  };
  const fecthFeeCrypto = async (rule, value) => {
    if (value === null) {
      return Promise.reject(` ${locale.t("withdraw_error.t_condition")}`);
    } else {
      try {
        const payload = {
          wallet_address: value,
          amount: form.getFieldValue("amount") * rateCrypto,
          currency_id: form.getFieldValue("currency"),
        };
        const { data } = await post(
          `${renderApiDomain() + `payment/crypto/fee`}`,
          payload
        );
        props.onChangeCryptoFee(data.data.fee);
        setFeeCrypto(data.data.fee);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(
          ` ${locale.t("withdraw_info.wallet_address_error")}`
        );
      }
    }
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe-black.svg";
  };
  const handleCloseScanner = () => {
    setOpenModalScan(false);
  };
  const handleSubmitQr = (value) => {
    form.setFieldsValue({
      wallet_address: value,
    });
    props.onChangeWalletAddress(value);
    fecthFeeCrypto(null, value);
    setOpenModalScan(false);
  };
  return (
    <div className="user withdraw-payment">
      {loading ? (
        <div
          className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
          style={{ height: 500 }}
        >
          <ClipLoader
            color="#36d7b7"
            size={72}
            cssOverride={{ borderWidth: 8 }}
          />
        </div>
      ) : (
        <>
          <span className="fs-20 font-weight-bold ">
            2.{locale.t("deposit_step.second_step.title")}
          </span>
          <div
            className="ptc-14 pbc-14 prc-20 plc-20 mt-3 "
            style={{
              backgroundColor: "#F8D04626",
              borderRadius: "10px",
              border: "1px solid #FFEDAD",
            }}
          >
            {locale.t("withdraw_info.withdraw_warning_text")}
          </div>
          <div className="my-3 text-lightgray-iux">
            {" "}
            {locale.t("dashboardWallet.account")}:
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <div className="box-payment-method">
                <div className="d-flex flex-column text-lightgray-iux fw-300">
                  <div>
                    {props.account?.platform}&nbsp;
                    {props.account?.account_number}
                    &nbsp; ({props.account?.account_group?.display})
                  </div>
                  <div className="text-main-black ">
                    {props.account?.account_type === "Cent" ? "¢" : "$"}
                    {props.account?.current_balance
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="box-payment-method">
                <div className="d-flex align-items-center text-lightgray-iux fw-300">
                  <img
                    src={`${process.env.REACT_APP_POLICY}${props.paymentSelected?.logo}`}
                    // src={`${process.env.PUBLIC_URL}/assets/images/bank/${props.paymentSelected?.logo}`}
                    alt="...."
                    width={40}
                    height={40}
                    style={{ borderRadius: 4 }}
                    onError={addDefaultSrc}
                  />
                  <div className="ml-2">
                    <div className="text-left w-100 text-main-black ">
                      {props.paymentSelected?.name}
                    </div>
                    <div>{`(${locale.t(
                      "deposit_step.second_step.minimun_withdraw"
                    )} ${props.paymentSelected?.payment_config[0]?.minimum_withdraw
                      ?.toFixed(2)
                      ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                      props.paymentSelected.payment_key === "b2binpay"
                        ? "USD"
                        : profileCountry?.symbol
                    })`}</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Form
            layout={"vertical"}
            name="deposit_amount"
            onFinish={onFinish}
            // onFinish={props.onNextStepPayment}
            className="user mx-auto"
            form={form}
            initialValues={{
              amount: props.amount,
              wallet_address:
                paymentKey === "b2binpay"
                  ? null
                  : props?.user?.profile?.phone_number,
              // currency:
              //   dataCrypto?.length > 0 ? dataCrypto[0]?.currency_id : 1000,
            }}
            // disabled={true}
          >
            <Row gutter={[16, 16]} className="mt-3">
              <Col xs={24} md={12}>
                {(paymentKey === "gcash_payout" ||
                  paymentKey === "limepay_mobile_money_payout" ||
                  paymentKey === "gate_mobile_money_payout" ||
                  paymentKey === "maya_payout") && (
                  <div>
                    <Form.Item
                      style={{ borderTop: "0" }}
                      name="wallet_address"
                      label={props.paymentSelected?.name + " Account"}
                    >
                      <Input
                        disabled
                        defaultValue={props.wallet_address}
                        value={props?.wallet_address}
                        type="text"
                      />
                    </Form.Item>
                  </div>
                )}

                {paymentKey === "b2binpay" && (
                  <Form.Item
                    name="currency"
                    label={locale.t("withdraw_info.currency")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      size="large"
                      placeholder="Select Currency"
                      // onChange={onCryptoChange}
                      // value={dataCrypto ? dataCrypto[0]?.currency_id : 1000}
                      // defaultValue={"btcusd"}
                    >
                      {dataCrypto?.map((e, i) => (
                        <Option value={e.currency_id} key={e.currency_id}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                <div>
                  <Form.Item
                    style={{ borderTop: "0" }}
                    name="amount"
                    validateStatus={
                      paymentKey === "b2binpay"
                        ? checkAmountBroker() && checkInsufficient()
                          ? "success"
                          : "error"
                        : checkAmount() &&
                          checkAmountBroker() &&
                          checkInsufficient()
                        ? "success"
                        : "error"
                    }
                    rules={[
                      {
                        validator:
                          paymentKey === "alphapo"
                            ? checkWithDrawAl
                            : paymentKey === "b2binpay"
                            ? checkWithDrawCrypto
                            : checkWithDraw,
                      },
                    ]}
                    label={locale.t("deposit_step.second_step.description")}
                  >
                    <Input
                      defaultValue={props.amount}
                      value={props?.amount}
                      disabled={props?.accountWelcome}
                      prefix={
                        props?.account?.account_type === "Cent" ? "¢" : "$"
                      }
                      suffix={
                        props?.account?.account_type === "Cent" ? "USC" : "USD"
                      }
                      type="text"
                      onKeyPress={handleKeyPress}
                      onChange={(event) => {
                        form.setFieldsValue({
                          amount: removeLeadingZeros(event.target.value),
                        });
                        props.onChangeAmount(
                          removeLeadingZeros(event.target.value)
                        );
                        setAmount(removeLeadingZeros(event.target.value));
                      }}
                    />
                  </Form.Item>
                  {/* {props.amount && ( */}
                  <div
                    className={`${
                      checkAmountBroker()
                        ? "text-primary-sub-iux"
                        : "text-danger"
                    }`}
                  >
                    <Badge
                      key={"amount_more_than_2"}
                      color={`${checkAmountBroker() ? "#1CB894" : "red"}`}
                    />
                    {paymentKey !== "b2binpay"
                      ? locale.t("deposit_step.second_step.amount_more_than")
                      : locale.t(
                          "deposit_step.second_step.total_amount_more_than"
                        )}
                    {props.account.account_type === "Cent"
                      ? accountDetail?.minimum_withdraw * 100
                      : accountDetail?.minimum_withdraw}
                    &nbsp;
                    {props.account.account_type === "Cent" ? `USC` : `USD`}
                  </div>
                  {/* )} */}
                  {paymentKey !== "b2binpay" && (
                    <div
                      className={`${
                        checkAmount() ? "text-primary-sub-iux" : "text-danger"
                      } `}
                    >
                      <Badge
                        key={"amount_more_than_2"}
                        color={`${checkAmount() ? "#1CB894" : "red"}`}
                      />
                      {locale.t("deposit_step.second_step.amount_more_than")}{" "}
                      {fCurrencySymbol(
                        props.paymentSelected?.payment_config[0]
                          ?.minimum_withdraw
                      )}{" "}
                      {profileCountry.symbol}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={24} md={12}>
                {paymentKey === "b2binpay" && (
                  <div>
                    <Form.Item
                      style={{ borderTop: "0" }}
                      name="wallet_address"
                      rules={[{ required: true, validator: fecthFeeCrypto }]}
                      label={locale.t("withdraw_info.wallet_address")}
                    >
                      <Input
                        type="text"
                        onBlur={(event) => {}}
                        onChange={(event) => {
                          props.onChangeWalletAddress(event.target.value);
                        }}
                        suffix={
                          <div
                            onClick={() => setOpenModalScan(true)}
                            className="cursor-pointer"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.86628 1.6001H3.02183C2.23636 1.6001 1.59961 2.23685 1.59961 3.02232V5.86676M5.86628 14.4001H3.02183C2.23636 14.4001 1.59961 13.7633 1.59961 12.9779V10.1334M10.1329 1.6001H12.9774C13.7629 1.6001 14.3996 2.23685 14.3996 3.02232V5.86676M14.3996 10.1334V12.9779C14.3996 13.7633 13.7629 14.4001 12.9774 14.4001H10.1329M1.59961 8.00029H14.3996"
                                stroke="black"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                        }
                      />
                    </Form.Item>
                  </div>
                )}
                <div className="w-12 text-lightgray-iux  pb-2">
                  {locale.t("deposit_step.second_step.you_receive")}
                </div>
                {paymentKey === "b2binpay" ? (
                  <>
                    <CryptoRecive
                      account={props?.account}
                      amount={props?.amount}
                      cryptoRate={rateCrypto}
                      currencySelected={dataCrypto?.find(
                        (e) => e.currency_id === form.getFieldValue("currency")
                      )}
                      cryptoFee={feeCrypto}
                      accountDetail={accountDetail}
                    />
                  </>
                ) : (
                  <>
                    <div className="box-payment-receive d-flex flex-column justify-content-between">
                      <div className="fw-300">
                        {paymentKey !== "gcash_payout" &&
                          paymentKey !== "limepay_mobile_money_payout" &&
                          paymentKey !== "gate_mobile_money_payout" &&
                          paymentKey !== "maya_payout" && (
                            <>
                              <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                                <div>
                                  {" "}
                                  {locale.t("withdraw_info.account.name")}
                                </div>
                                <div>{bookBankVerify?.bank_account_name}</div>
                              </div>
                              <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                                <div>
                                  {" "}
                                  {locale.t("withdraw_info.account.number")}
                                </div>
                                <div>{bookBankVerify?.bank_account_number}</div>
                              </div>
                              <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                                <div>
                                  {locale.t("withdraw_info.account.bank")}
                                </div>
                                <div className="text-right">
                                  {bookBankVerify?.bank_name}
                                </div>
                              </div>
                            </>
                          )}

                        <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>
                            {paymentKey !== "gcash_payout" &&
                            paymentKey !== "limepay_mobile_money_payout" &&
                            paymentKey !== "gate_mobile_money_payout" &&
                            paymentKey !== "maya_payout"
                              ? locale.t("deposit_info.currency_rate")
                              : locale.t("withdraw_info.rate")}
                            &nbsp;{" "}
                            <Tooltip
                              placement="topLeft"
                              title={
                                <div className="fs-10">
                                  {locale.t("faq.notice")}
                                </div>
                              }
                            >
                              <InfoCircleOutlined className="text-lightgray fs-16" />
                            </Tooltip>
                          </div>

                          <span>
                            {fCurrencySymbol(rate.toFixed(2))}&nbsp;USD/
                            {profileCountry?.symbol}
                          </span>
                        </div>
                        <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
                          {paymentKey !== "gcash_payout" &&
                          paymentKey !== "limepay_mobile_money_payout" &&
                          paymentKey !== "gate_mobile_money_payout" &&
                          paymentKey !== "maya_payout"
                            ? locale.t("deposit_step.second_step.fee")
                            : locale.t("withdraw_info.fee")}
                          <span>0.00 USD</span>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="box-payment-resultamount d-flex justify-content-between">
                      <div>
                        {locale.t("deposit_step.second_step.description")}
                      </div>
                      <div>
                        {renderRateWithdraw()} {profileCountry?.symbol}
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <div className="d-flex justify-content-between mt-3">
              <Button
                className="btn-outline-iux  mr-1 button-default-w "
                onClick={props.onBackStep}
              >
                {locale.t("back")}
              </Button>

              <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  return (
                    <Button
                      loading={buttonLoading}
                      // style={{ minWidth: 163 }}
                      className="btn-primary-new-iux  ml-1 button-default-w "
                      // onClick={props.onNextStepPayment}
                      disabled={
                        buttonLoading ||
                        props?.amount === 0 ||
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length > 0 ||
                        (paymentKey === "b2binpay" &&
                          getFieldsValue("wallet_address").wallet_address ===
                            null)
                      }
                      htmlType="submit"
                    >
                      {locale.t("next")}
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
            <ModalScanQr
              open={openModalScan}
              handleCancel={handleCloseScanner}
              onResult={handleSubmitQr}
              checkCodeFeeCrypto={checkCodeFeeCrypto}
            />
          </Form>
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  const { bookBankVerify } = user;
  return { bookBankVerify, user };
};

export default connect(mapStateToProps, null)(WithDrawSecondStep);
