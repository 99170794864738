export default {
  th: {
    login_seconds: "seconds",
    otp_email: "อีเมล",
    otp_phonenumber: "หมายเลขโทรศัพท์",
    login_title: "เข้าสู่ระบบ",
    login_registrt: "คุณไม่มีบัญชี ?",
    login_your_email: "อีเมลของคุณ",
    login_your_password: "รหัสผ่าน",
    forgot_your_password: "ลืมรหัสผ่าน ?",
    login_button_text: "ดำเนินการ",
    login_lable_bottom:
      "การซื้อขาย CFDs และผลิตภัณฑ์ที่ใช้เลเวอเรจโดยทั่วไปมีความเสี่ยงสูงที่อาจขาดทุน และคุณอาจสูญเสียเงินลงทุนทั้งหมด\n",
    forgot_pass_title: `ป้อนอีเมลที่คุณใช้ในการลงทะเบียน IUX และเราจะช่วยคุณสร้างไฟล์ รหัสผ่าน`,
    already_account: "คุณมีบัญชีพร้อมใช้งาน",
    login_top_1: `ความรับผิดชอบในการค้า: CFDs เป็นเครื่องมือที่ซับซ้อนและมีความเสี่ยงสูงที่จะสูญเสียเงินอย่างรวดเร็ว เนื่องจากเลเวอเรจ`,
    login_top_2: `67.83% ของบัญชีนักลงทุนรายย่อยสูญเสียเงินเมื่อทำการซื้อขาย CFDs กับผู้ให้บริการรายนี้`,
    login_top_3: `คุณควรพิจารณาว่าคุณเข้าใจวิธีการทำงานของ CFDs หรือไม่ และคุณสามารถจ่ายได้หรือไม่  เพื่อรับความเสี่ยงสูงที่จะสูญเสียเงินของคุณ`,
    otp_verification: "ต้องการการยืนยันตัวตน",
    otp_verification_des: "กรอกรหัสที่ส่งไปยัง%{type} : %{email}",
    otp_ref: "รหัสอ้างอิง",
    have_not_received: "ไม่ได้รับรหัสใช่ไหม? ส่งรหัสใหม่ได้ใน %{countDown}",
    resend_code: "ส่งรหัสใหม่",
    resend_code_in:
      "ส่งรหัสอีกครั้งใน %{countDown} <span class='text-primary-new-iux'>วินาที</span>",
    remind_me_later: "เตือนฉันภายหลัง",
    goto_setting: "ไปที่การตั้งค่า",
    starting2fa:
      "เริ่มตั้งแต่วันที่ 31 ตุลาคม 2024 \nเป็นต้นไป IUX จะต้องใช้ OTP ผ่านทางอีเมลสำหรับการเข้าสู่ระบบ",
  },
  lo: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "phone number",
    login_title: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
    login_registrt: "ທ່ານບໍ່ມີບັນຊີ ?",
    login_your_email: "ອີເມວຂອງທ່ານ",
    login_your_password: "ລະຫັດຜ່ານ",
    forgot_your_password: "ລືມລະຫັດຜ່ານຂອງທ່ານບໍ?",
    login_button_text: "ດໍາເນີນການ",
    login_lable_bottom:
      "ການຄ້າ CFDs ແລະຜະລິດຕະພັນທີ່ໃຊ້ Valor Reggio ໂດຍທົ່ວໄປມີຄວາມສ່ຽງສູງ ການສູນເສຍທີ່ຈະເກີດຂື້ນ ແລະທ່ານອາດຈະສູນເສຍເງີນທັງ ໝົດ\n",
    forgot_pass_title: `ກະລຸນາໃສ່ອີເມວທີ່ທ່ານເຄີຍລົງທະບຽນ ສຳ ລັບ IUX ແລະພວກເຮົາຈະຊ່ວຍທ່ານສ້າງເອກະສານລະຫັດ`,
    already_account: "ທ່ານມີບັນຊີທີ່ຈະເຂົ້າ",
    login_top_1: `ການຄ້າທີ່ມີຄວາມຮັບຜິດຊອບ: CFDs ແມ່ນເຄື່ອງມືທີ່ສັບສົນແລະມາພ້ອມກັບຄວາມສ່ຽງສູງທີ່ຈະສູນເສຍເງິນຢ່າງໄວວາເນື່ອງຈາກ leverage`,
    login_top_2: `67.83% ຂອງບັນຊີນັກລົງທຶນຂາຍຍ່ອຍຈະສູນເສຍເງິນໃນເວລາທີ່ການຊື້ຂາຍ CFDs ກັບຜູ້ໃຫ້ບໍລິການນີ້.`,
    login_top_3: `ທ່ານຄວນພິຈາລະນາວ່າທ່ານເຂົ້າໃຈວິທີການ CFDs ເຮັດວຽກແລະວ່າທ່ານສາມາດເອົາຄວາມສ່ຽງສູງຂອງການສູນເສຍເງິນຂອງທ່ານໄດ້ຫຼືບໍ່`,
    otp_verification: "Verification Required",
    otp_verification_des: "Enter the code that was sent to %{type} %{email}",
    otp_ref: "Ref",
    have_not_received: "Haven't Received it? Resend code in %{countDown}",
    resend_code: "Resend Code",
    resend_code_in:
      "Resend code in %{countDown} <span class='text-primary-new-iux'>seconds</span>",
    remind_me_later: "Remind me later",
    goto_setting: "Go to Setting",
    starting2fa:
      "Starting October 31, 2024,\n IUX will require OTP via email for login",
  },
  en: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "phone number",
    login_title: "Log in",
    login_registrt: "Do not have an account?",
    login_your_email: "Your email",
    login_your_password: "Password",
    forgot_your_password: "Forgot password?",
    login_button_text: "process",
    login_lable_bottom:
      "Trading CFDs and leveraged products generally involves a high risk of loss and you may lose all your investment.",
    forgot_pass_title: `Enter the email you have used to register with IUX and we will help you create a new  password.`,
    already_account: "Already have an account?",
    login_top_1: `Trade Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.`,
    login_top_2: `67.83% of retail investor accounts lose money when trading CFDs with this provider.`,
    login_top_3: `You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
    otp_verification: "Verification Required",
    otp_verification_des: "Enter the code that was sent to %{type} : %{email}",
    otp_ref: "Ref",
    have_not_received: "Haven’t Received it? Resend code in %{countDown}",
    resend_code: "Resend Code",
    resend_code_in:
      "Resend code in %{countDown} <span class='text-primary-new-iux'>seconds</span>",
    remind_me_later: "Remind me later",
    goto_setting: "Go to Setting",
    starting2fa:
      "Starting October 31, 2024, \nIUX will require OTP via email for login",
  },
  hi: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "phone number",
    login_title: "Log in",
    login_registrt: "Do not have an account?",
    login_your_email: "Your email",
    login_your_password: "Password",
    forgot_your_password: "Forgot password?",
    login_button_text: "process",
    login_lable_bottom:
      "Trading CFDs and leveraged products generally involves a high risk of loss and you may lose all your investment.",
    forgot_pass_title: `Enter the email you have used to register with IUX and we will help you create a new  password.`,
    already_account: "Already have an account?",
    login_top_1: `Trade Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.`,
    login_top_2: `67.83% of retail investor accounts lose money when trading CFDs with this provider.`,
    login_top_3: `You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
    otp_verification: "Verification Required",
    otp_verification_des: "Enter the code that was sent to %{type} : %{email}",
    otp_ref: "Ref",
    have_not_received: "Haven’t Received it? Resend code in %{countDown}",
    resend_code: "Resend Code",
    resend_code_in:
      "Resend code in %{countDown} <span class='text-primary-new-iux'>seconds</span>",
    remind_me_later: "Remind me later",
    goto_setting: "Go to Setting",
    starting2fa:
      "Starting October 31, 2024,\n IUX will require OTP via email for login",
  },
  ms: {
    login_seconds: "seconds",
    otp_email: "emel",
    otp_phonenumber: "nombor telefon",

    login_title: `Log masuk`,
    login_registrt: "Anda tidak memiliki akaun?",
    login_your_email: `Emel anda`,
    login_your_password: `Kata laluan`,
    forgot_your_password: `Lupa Kata laluan?`,
    login_button_text: `Laksanakan`,
    login_lable_bottom: `Berdagang CFDs dan produk berleveraj secara umumnya melibatkan tahap risiko kerugian yang tinggi dan anda mungkin kehilangan semua pelaburan anda.`,
    forgot_pass_title: `Masukkan e-mel yang telah anda gunakan untuk mendaftar dengan IUX dan kami akan membantu anda membuat kata laluan baharu.`,
    already_account: "Sudah mempunyai akaun?",
    login_top_1: `Berdagang Secara Bertanggungjawab: CFDs ialah instrumen yang kompleks dan datang dengan risiko tinggi. Kehilangan wang dengan cepat disebabkan oleh leverage.`,
    login_top_2: `67.83% daripada akaun pelabur runcit kehilangan wang apabila berdagang CFDs dengan penyedia ini.`,
    login_top_3: `Anda harus mempertimbangkan sama ada anda memahami cara CFDs berfungsi dan sama ada anda mampu untuk mengambil risiko tinggi kehilangan wang anda.`,
    otp_verification: "Pengesahan Diperlukan",
    otp_verification_des: "Masukkan kod yang dihantar ke %{type} : %{email}",
    otp_ref: "Rujukan",
    have_not_received:
      "Tidak menerima kod? Hantar semula kod dalam %{countDown}",
    resend_code: "Hantar Semula Kod",
    resend_code_in:
      "Hantar semula kod dalam %{countDown} <span class='text-primary-new-iux'>saat</span>",
    remind_me_later: "Ingatkan Saya Kemudian",
    goto_setting: "Pergi ke Tetapan",
    starting2fa:
      "Mulai 31 Oktober 2024,\n IUX akan memerlukan OTP melalui e-mel untuk log masuk.",
  },
  id: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "nomor telepon",

    login_title: `Gabung`,
    login_registrt: "Do not have an account?",
    login_your_email: `Email mu`,
    login_your_password: `Kata sandi`,
    forgot_your_password: `Forgot password?`,
    login_button_text: `memproses`,
    already_account: "Sudah memiliki akun?",
    login_lable_bottom: `perdagangan CFDs dan produk menggunakan Valor Reggio umumnya memiliki risiko yang lebih tinggi dari kerugian yang mungkin terjadi. Dan Anda bisa kehilangan uang untuk semua investor.`,
    forgot_pass_title: `Enter the email you have used to register with IUX and we will help you create a new  password.`,
    login_top_1: `Trade Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.`,
    login_top_2: `67.83% of retail investor accounts lose money when trading CFDs with this provider.`,
    login_top_3: `You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
    otp_verification: "Verifikasi Diperlukan",
    otp_verification_des: "Masukkan kode yang dikirim ke %{type} : %{email}",
    otp_ref: "Ref",
    have_not_received:
      "Belum Menerimanya? Hantar semula kod dalam %{countDown}",
    resend_code: "Kirim Ulang Kode",
    resend_code_in:
      "Kirim ulang kode dalam %{countDown} <span class='text-primary-new-iux'>detik</span>",
    remind_me_later: "Ingatkan Saya Nanti",
    goto_setting: "Buka Pengaturan",
    starting2fa:
      "Mulai 31 Oktober 2024,\n IUX akan meminta OTP melalui email untuk login.",
  },
  zh: {
    login_seconds: "seconds",
    otp_email: "电子邮件",
    otp_phonenumber: "电话号码",

    login_title: "登录",
    login_registrt: "还没有账户？",
    login_your_email: "你的邮箱",
    login_your_password: "密码",
    forgot_your_password: "忘记密码？",
    login_button_text: "登录",
    login_lable_bottom:
      "差价合约（CFD）和杠杆产品的交易通常涉及较高的亏损风险，您可能会损失所有投资。",
    forgot_pass_title:
      "输入您用于注册 IUX 的电子邮件，我们将帮助您创建新密码。",
    already_account: "已经有账户？",
    login_top_1:
      "负责任地交易：差价合约是复杂的金融工具，由于杠杆作用，存在快速亏损的高风险。",
    login_top_2: "67.83% 的零售投资者账户在交易差价合约时亏损。",
    login_top_3:
      "您应考虑自己是否理解差价合约的运作方式，以及是否有能力承担亏损的高风险。",
    otp_verification: "需要验证",
    otp_verification_des: "输入发送到 的代码 %{type} : %{email}",
    otp_ref: "参考",
    have_not_received: "没有收到？ 重新发送代码在 %{countDown}",
    resend_code: "重新发送代码",
    resend_code_in:
      "%{countDown} <span class='text-primary-new-iux'>秒</span> 秒后重新发送代码",
    remind_me_later: "稍后提醒我",
    goto_setting: "转到设置",
    starting2fa:
      "从 2024 年 10 月 31 日开始，IUX 将需要通过电子邮件的 OTP 进行登录。",
  },
  vi: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "số điện thoại",

    login_title: "Đăng nhập",
    login_registrt: "Bạn không có tài khoản ?",
    login_your_email: "E-mail của bạn",
    login_your_password: "Mật khẩu",
    forgot_your_password: "Quên mật khẩu?",
    login_button_text: "Thực hiện",
    login_lable_bottom:
      "Việc giao dịch CFDs và các sản phẩm có đòn bẩy thông thường có rủi ro cao và bạn có thể mất tất cả số tiền đầu tư.\n",
    forgot_pass_title: `Nhập e-mail bạn đã sử dụng để đăng ký IUX và chúng tôi sẽ giúp bạn tạo tệp mật khẩu.`,
    already_account: "Bạn đã có tài khoản",
    login_top_1: `Trách nhiệm giao dịch: CFDs là công cụ phức tạp và có rủi ro cao và có thể mất tiền nhanh chóng, do đòn bẩy`,
    login_top_2: `85,32% của tài khoản nhà đầu tư bán lẻ bị mất tiền khi giao dịch CFDs với nhà cung cấp này.`,
    login_top_3: `Bạn nên xem xét và hiểu cách hoạt động của CFDs hay không, và bạn có  thể trả tiền được hay không? để nhận rủi ro cao về việc mất tiền của bạn.`,
    otp_verification: "Yêu Cầu Xác Minh",
    otp_verification_des: "Nhập mã đã được gửi đến %{type} : %{email}",
    otp_ref: "Tham chiếu",
    have_not_received: "Chưa nhận được mã? Hãy gửi lại mã trong %{countDown}",
    resend_code: "Gửi Lại Mã",
    resend_code_in:
      "Gửi lại mã trong %{countDown} <span class='text-primary-new-iux'>giây</span>",
    remind_me_later: "Nhắc Tôi Sau",
    goto_setting: "Đi tới Cài Đặt",
    starting2fa:
      "Bắt đầu từ ngày 31 tháng 10 năm 2024, \nIUX sẽ yêu cầu OTP qua email để đăng nhập.",
  },
  ko: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "phone number",
    login_title: `로그인`,
    login_registrt: "Do not have an account?",
    login_your_email: `귀하의 이메일`,
    login_your_password: `암호`,
    forgot_your_password: `Forgot password?`,
    login_button_text: `발하다`,
    login_lable_bottom: `CFDs 거래와 용맹 레지오을 사용하는 제품은 일반적으로 발생할 수 있습니다 손실의 위험이 높다. 그리고 당신은 모든 투자자 돈을 잃을 수 있습니다`,
    forgot_pass_title: `Enter the email you have used to register with IUX and we will help you create a new  password.`,
    already_account: "Already have an account?",
    login_top_1: `Trade Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.`,
    login_top_2: `67.83% of retail investor accounts lose money when trading CFDs with this provider.`,
    login_top_3: `You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
    otp_verification: "Verification Required",
    otp_verification_des: "Enter the code that was sent to %{type} : %{email}",
    otp_ref: "Ref",
    have_not_received: "Haven’t Received it? Resend code in %{countDown}",
    resend_code: "Resend Code",
    resend_code_in:
      "Resend code in %{countDown} <span class='text-primary-new-iux'>seconds</span>",
    remind_me_later: "Remind me later",
    goto_setting: "Go to Setting",
    starting2fa:
      "Starting October 31, 2024,\n IUX will require OTP via email for login",
  },
  fil: {
    login_seconds: "seconds",
    otp_email: "email",
    otp_phonenumber: "phone number",

    login_title: `Mag log in`,
    login_registrt: "Do not have an account?",
    login_your_email: `Ang email mo`,
    login_your_password: `password`,
    forgot_your_password: `Forgot password?`,
    login_button_text: `magpatuloy`,
    login_lable_bottom: `CFDs kalakalan at mga produkto gamit Valor Reggio sa pangkalahatan ay may isang mas mataas na peligro ng pagkawala na maaaring magresulta. At maaari kang mawalan ng pera para sa lahat ng mga namumuhunan`,
    forgot_pass_title: `Enter the email you have used to register with IUX and we will help you create a new  password.`,
    already_account: "Mayroon nang account?",
    login_top_1: `Trade Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage.`,
    login_top_2: `67.83% of retail investor accounts lose money when trading CFDs with this provider.`,
    login_top_3: `You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
    otp_verification: "Verification Required",
    otp_verification_des: "Enter the code that was sent to %{type} : %{email}",
    otp_ref: "Ref",
    have_not_received: "Haven’t Received it? Resend code in %{countDown}",
    resend_code: "Resend Code",
    resend_code_in:
      "Resend code in %{countDown} <span class='text-primary-new-iux'>seconds</span>",
    remind_me_later: "Remind me later",
    goto_setting: "Go to Setting",
    starting2fa:
      "Starting October 31, 2024, \nIUX will require OTP via email for login",
  },
  ja: {
    login_seconds: "seconds",
    otp_email: "メール",
    otp_phonenumber: "電話番号",
    login_title: "ログイン",
    login_registrt: "アカウントを持っていないのですか？",
    login_your_email: "あなたのメール",
    login_your_password: "パスワード",
    forgot_your_password: "パスワードをお忘れですか？",
    login_button_text: "プロセス",
    login_lable_bottom:
      "CFDとレバレッジされた製品を取引するには、一般に損失のリスクが高く、すべての投資を失う可能性があります。",
    forgot_pass_title:
      "IUX市場に登録するために使用したメールを入力してください。新しいパスワードを作成するのに役立ちます。",
    already_account: "すでにアカウントをお持ちですか？",
    login_top_1:
      "責任を持って貿易：CFDは複雑な楽器であり、レバレッジのために急速にお金を失うリスクが高くなります。",
    login_top_2:
      "このプロバイダーとCFDを取引すると、小売投資家アカウントの67.83％がお金を失います。",
    login_top_3:
      "CFDがどのように機能するか、そしてお金を失うリスクを冒す余裕があるかどうかを理解する必要があります。",
    otp_verification: "確認が必要",
    otp_verification_des:
      "%{type} : %{email} に送信されたコードを入力してください。",
    otp_ref: "参照",
    have_not_received: "コードが届いていませんか？ %{countDown} 後に再送信",
    resend_code: "再送信",
    resend_code_in:
      "再送信するまで%{countDown}<span class='text-primary-new-iux'>秒</span>",
    remind_me_later: "後で通知する",
    goto_setting: "設定へ移動",
    starting2fa:
      "2024年10月31日より、IUXでは、ログインに電子メール経由の OTP が必要になります。",
  },
  es: {
    login_seconds: "seconds",
    otp_email: "correo electrónico",
    otp_phonenumber: "número de teléfono",

    login_title: "Iniciar sesión",
    login_registrt: "¿No tienes una cuenta?",
    login_your_email: "Tu correo electrónico",
    login_your_password: "Contraseña",
    forgot_your_password: "¿Olvidaste tu contraseña?",
    login_button_text: "Iniciar sesión",
    login_lable_bottom:
      "El comercio de CFDs y productos apalancados generalmente implica un alto riesgo de pérdida y puede perder toda su inversión.",
    forgot_pass_title:
      "Ingrese el correo electrónico que utilizó para registrarse en IUX y le ayudaremos a crear una nueva contraseña.",
    already_account: "¿Ya tienes una cuenta?",
    login_top_1:
      "Opera de manera responsable: los CFDs son instrumentos complejos y conllevan un alto riesgo de perder dinero rápidamente debido al apalancamiento.",
    login_top_2:
      "El 67.83% de las cuentas de inversores minoristas pierden dinero al negociar CFDs con este proveedor.",
    login_top_3:
      "Debe considerar si comprende cómo funcionan los CFDs y si puede permitirse asumir el alto riesgo de perder su dinero.",
    otp_verification: "Verificación Requerida",
    otp_verification_des:
      "Introduce el código que se envió a %{type} : %{email}",
    otp_ref: "Ref",
    have_not_received: "¿No lo has recibido? Reenviar código en %{countDown}",
    resend_code: "Reenviar Código",
    resend_code_in:
      "Reenviar código en %{countDown} <span class='text-primary-new-iux'>segundos</span>",
    remind_me_later: "Recuérdamelo más tarde",
    goto_setting: "Ir a Configuración",
    starting2fa:
      "A partir del 31 de octubre de 2024,\n IUX requerirá OTP a través de correo electrónico para iniciar sesión.",
  },
};
