import React, { useState, useEffect, useMemo } from "react";
import { Button, Input, Form, message } from "antd";
import {
  authentication,
  getUser,
  LogoutAuth,
  getBank,
  getUserv2,
  getUserLogin,
} from "../../actions";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import FooterAuthPage from "../../components/footer-landing-page/FooterAuth";
import { post } from "../../helper/request";
import { auth } from "../../firebase";
import {
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { renderApiDomain } from "../../helper/renderApiDomain";
import OtpInput from "../../components/otpinput/OtpInput";
import { postUserOTP, postUserOTPVerify } from "../../api/verifyuser";
import { ClipLoader } from "react-spinners";
import ImportantZa from "../../components/important-note/important-za";
import useLocationStorage from "../../helper/useLocationStorage";
import NavbarAuth from "../../components/navbar/NavbarAuth";
import { Logo } from "@vt/vt-ui";

const TITLE = "Login | IUX";
const locale = require("react-redux-i18n").I18n;
const Translate = require("react-redux-i18n").Translate;
const WarningBox = styled.div`
  border: 1px solid #d1870054;
  background: #ffa50012;
  display: flex;
  padding: 12px 12px;
  align-items: center;
`;
const textcenter = {
  textAlign: "center",
  padding: "10px 0px 0px",
  fontSize: "24px",
  fontWeight: 700,

  color: "#FFF",
};
const inputlabel = {
  height: "42px",
};

export const LoginPage = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  let isStepOtp = urlParams?.get("otp");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [warning, setWarning] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [error, setError] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [countDownOTP, setCountDownOTP] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [stepOTP, setStepOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [dataEmailOtp, setDataEmailOtp] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const isuser = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [targetTime, setTargetTime] = useState(null);
  // const [targetDate, settargetDate] = useState("");

  // const targetDate = new Date("2024-10-25T10:04:38.938Z");
  const countDownDuration = 3 * 60 * 1000; // 3 minutes in milliseconds

  useEffect(() => {
    const targetDate = new Date(targetTime);
    const endTime = new Date(targetDate?.getTime() + countDownDuration);
    const updateCountdown = () => {
      const now = new Date();
      const timeDiff = endTime?.getTime() - now?.getTime(); // Difference in milliseconds

      if (timeDiff <= 0) {
        clearInterval(myInterval);
        setCountDownOTP(0);
      } else {
        const remainingSeconds = Math.floor(timeDiff / 1000);
        setCountDownOTP(remainingSeconds);
      }
    };

    const myInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [targetTime]);

  const onResend = async () => {
    try {
      setLoadingResend(true);
      await axios
        .post(renderApiDomain() + "resend-verify-email", {
          email: email,
        })
        .then((res) => {
          setCountDown(59);
          setError(locale.t("validate_email_seccuss"));
        });
      setLoadingResend(false);
    } catch (e) {
      setError(e.message);
      setLoadingResend(false);
    }
  };
  const onChange = (value) => {
    setOtp(value);

    if (value.trim().length === 6) {
      // setIsError(true);
      confirmOtp(value);
    } else {
      setIsError(false);
    }
  };
  const confirmOtp = async (value) => {
    setIsLoading(true);
    try {
      const payload = {
        code: value,
        reference: dataEmailOtp?.reference,
      };

      const { data } = await postUserOTPVerify(payload);
      setIsSuccess(true);

      signInWithCustomToken(auth, data.data.token)
        .then((userCredential) => {
          setIsLoading(false);
          onLoginPass(userCredential);
        })
        .catch((error) => {
          setIsLoading(false);
          const errorCode = error.code;
          switch (errorCode) {
            case "auth/user-not-found":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("User  not found");
              // Handle the error for when the user is not found
              break;
            case "auth/wrong-password":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("password is incorrect");
              // Handle the error for when the password is incorrect
              break;
            case "auth/invalid-email":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("email is invalid");
              // Handle the error for when the email is invalid
              break;
            // Handle other error codes as needed
            default:
              message.error("Something went wrong");
              // setError("Something went wrong");
              // Handle any other errors
              break;
          }
        });
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          setLoading(false);
        } else {
          setMinutes(minutes - 1);
          setCountDown(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [countDown]);
  const onLoginPass = (userCredential) => {
    const user = userCredential.user;

    if (user.emailVerified) {
      user
        .getIdToken()
        .then(async (token) => {
          if (isuser?.session_id) {
            let sessionId = await window.seon.getBase64Session();
            let payload = {
              session: sessionId,
              session_id: isuser?.session_id,
              source: "web",
            };
            try {
              const { data } = await post(
                `${process.env.REACT_APP_API_URL}seon`,
                payload
              );
            } catch (error) {
              console.log(error);
            }
            // dispatch(getBank(isuser.personal?.country_id));
          } else {
            props
              .getUserv2()
              .then(async (response) => {
                if (response.success) {
                  let sessionId = await window.seon.getBase64Session();
                  let payload = {
                    session: sessionId,
                    session_id: response?.data?.data?.session_id,
                    source: "web",
                  };
                  try {
                    const { data } = await post(
                      `${process.env.REACT_APP_API_URL}seon`,
                      payload
                    );
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  if (response.data.data.code === 1010) {
                    setStepOTP(true);

                    fecthOtp();
                  }
                }

                // dispatch(
                //   getBank(response.data?.data?.personal_detail?.country_id)
                // );
              })
              .catch((error) => {
                console.log(error);
              });
          }
          dispatch(getUserLogin());
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      auth?.signOut();
      setWarning(true);
      message.error("Please verify your email first");
    }
  };
  const getAccessToken = async (props) => {
    localStorage.removeItem("tutorialHide");

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        onLoginPass(userCredential);
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/user-not-found":
            message.error(
              "Incorrect email or password. Please check your credentials and try again."
            );
            // setError("User  not found");
            // Handle the error for when the user is not found
            break;
          case "auth/wrong-password":
            message.error(
              "Incorrect email or password. Please check your credentials and try again."
            );
            // setError("password is incorrect");
            // Handle the error for when the password is incorrect
            break;
          case "auth/invalid-email":
            message.error(
              "Incorrect email or password. Please check your credentials and try again."
            );
            // setError("email is invalid");
            // Handle the error for when the email is invalid
            break;
          // Handle other error codes as needed
          default:
            message.error("Something went wrong");
            // setError("Something went wrong");
            // Handle any other errors
            break;
        }
      });
  };
  const onInputChangeEmail = (event) => {
    const {
      target: { name, value },
    } = event;
    setEmail(value);
  };
  const onInputChangePassword = (event) => {
    const {
      target: { name, value },
    } = event;
    setPassword(value);
  };
  const fecthOtp = async () => {
    setLoadingOTP(true);
    try {
      const { data } = await postUserOTP();
      setDataEmailOtp(data.data);
      setTargetTime(data?.data?.created);
      // setCountDownOTP(180);
      setTimeout(() => {
        setLoadingOTP(false);
      }, 1000);
    } catch (error) {
      setStepOTP(false);
      setLoadingOTP(false);
      console.log(error);
    }
  };
  function formatCountdown(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }
  useEffect(() => {
    const handlePopState = (event) => {
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(
      null,
      "",
      `/${localStorage.getItem("locale")}/login`
    );
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [stepOTP]);
  useEffect(() => {
    if (isStepOtp) {
      setStepOTP(true);
      fecthOtp();
    }
  }, [isStepOtp]);
  const [location] = useLocationStorage();
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="bg-black">
        <NavbarAuth />
        <div className="container">
          <div className="text-center text-white">
            {locale.t("login_top_1")}&nbsp;
            <span className="font-bold-iux">
              {locale.t("login_top_2")}&nbsp;
            </span>
            {locale.t("login_top_3")}
          </div>
          {/* if you have to check ImportantZa component please change condition location !== 'ZA'  */}
          {location === "ZA" && <ImportantZa />}
        </div>
      </div>
      <section className="d-flex flex-column h-100 bg-black">
        <div
          className="text-center rectangle-card rectangle-card-login ml-auto mr-auto  position-relative"
          style={{ marginBottom: "21px", marginTop: "47px" }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <a href="http://iux.com/">
              <div style={{ width: "72px", height: "72px" }}>
                <Logo variant="withoutBg" size="72px" />
              </div>
            </a>
          </div>{" "}
          {stepOTP ? (
            <div className="">
              <h5 style={textcenter}>{locale.t("otp_verification")}</h5>
              <div>
                <p className=" mt-4  flex-wrap text-gray-login">
                  <Translate
                    value="otp_verification_des"
                    email={`<span class="text-primary-new-iux" >
                            ${dataEmailOtp?.to}
                            </span>`}
                    type={`${
                      dataEmailOtp?.type === "phone"
                        ? locale.t("otp_phonenumber")
                        : locale.t("otp_email")
                    }`}
                    dangerousHTML
                  />
                </p>
                <p className="mt-0 text-gray-login ">
                  {locale.t("otp_ref")} : {dataEmailOtp?.reference}
                </p>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: 40 }}
              >
                <OtpInput
                  value={otp}
                  valueLength={6}
                  onChange={onChange}
                  isError={isError}
                  isSuccess={isSuccess}
                  darkmode={true}
                />
              </div>
              <span
                className="text-primary-new-iux"
                style={{ color: "#1cb894" }}
              ></span>
              <div className="mt-3">
                {!loadingOTP ? (
                  <>
                    {countDownOTP !== 0 || minutes !== 0 ? (
                      <p className=" flex-wrap text-gray-login fs-14">
                        <Translate
                          value="have_not_received"
                          countDown={`
                           <span class="text-primary-new-iux font-weight-bolder" >
                             ${String(Math.floor(countDownOTP / 60)).padStart(
                               2,
                               "0"
                             )}:${String(countDownOTP % 60).padStart(2, "0")}
                           </span>`}
                          dangerousHTML
                        />
                      </p>
                    ) : (
                      <span
                        className="text-primary-new-iux font-weight-bold cursor-pointer"
                        onClick={() => fecthOtp()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.50065 1.83334C2.86884 1.83334 3.16732 2.13182 3.16732 2.50001V4.33335L4.05604 3.53104C5.23505 2.47579 6.79362 1.83334 8.50065 1.83334C12.1825 1.83334 15.1673 4.81811 15.1673 8.50001C15.1673 12.1819 12.1825 15.1667 8.50065 15.1667C5.32709 15.1667 2.67289 12.9498 1.99919 9.98087C1.91771 9.62181 2.14274 9.26468 2.5018 9.1832C2.86087 9.10173 3.21799 9.32676 3.29947 9.68582C3.83838 12.0608 5.96332 13.8333 8.50065 13.8333C11.4462 13.8333 13.834 11.4455 13.834 8.50001C13.834 5.55449 11.4462 3.16668 8.50065 3.16668C7.13476 3.16668 5.89018 3.67927 4.9463 4.52362L4.23399 5.16668H5.83398C6.20217 5.16668 6.50065 5.46515 6.50065 5.83334C6.50065 6.20153 6.20217 6.50001 5.83398 6.50001H2.50065C2.13246 6.50001 1.83398 6.20153 1.83398 5.83334V2.50001C1.83398 2.13182 2.13246 1.83334 2.50065 1.83334Z"
                            fill="#1CB894"
                          />
                        </svg>
                        &nbsp; {locale.t("resend_code")}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <ClipLoader
                      color="#36d7b7"
                      // size={12}
                      // cssOverride={{ borderWidth: 8 }}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <Form onFinish={() => getAccessToken(props)}>
              <h5 style={textcenter}>{locale.t("login_title")}</h5>
              <div>
                <p className="d-flex justify-content-between mt-4 mb-4 flex-wrap">
                  <span className="text-white">
                    {locale.t("login_registrt")}
                  </span>
                  <Link to={`/${localStorage.getItem("locale")}/register`}>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: "sans-serif",
                        fontWeight: "700",
                        marginLeft: "6px",
                      }}
                      className="text-primary-new-iux"
                    >
                      {locale.t("register")}
                    </span>
                  </Link>
                </p>
              </div>
              <div style={{ marginTop: "10px" }}></div>
              <div className="text-left text-gray mb-1">
                {locale.t("login_your_email")}
              </div>
              <Form.Item
                style={{ height: "40px" }}
                placeholder={locale.t("login_your_email")}
                defaultValue={email}
                name="email"
                onChange={onInputChangeEmail}
                // prefix={<Icon type="mail" style={{ color: "#a0a0a0" }} />}
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  className="login"
                  name="email"
                  style={inputlabel}
                  placeholder={locale.t("login_your_email")}
                />
              </Form.Item>
              <div className="text-left text-gray mb-1">
                {locale.t("login_your_password")}
              </div>
              <Form.Item
                placeholder={locale.t("login_your_password")}
                defaultValue={password}
                name="password"
                type="password"
                onChange={onInputChangePassword}
                style={{ background: "#242424" }}
                // prefix={
                //   <Icon type="lock" style={{ color: "#a0a0a0 !important" }} />
                // }
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  name="password"
                  type="password"
                  style={inputlabel}
                  placeholder={locale.t("login_your_password")}
                  // visibilityToggle={false}
                  // iconRender={
                  //   <Icon type="lock" style={{ color: "#fff !important" }} />
                  // }
                />
              </Form.Item>
              <Link
                to={`/${localStorage.getItem("locale")}/forgot-password-form`}
              >
                <div
                  type="link"
                  className="w-100 text-right pr-4 text-primary-new-iux cursor-pointer"
                >
                  {locale.t("forgot_your_password")}
                </div>
              </Link>
              {warning && (
                <WarningBox className="justify-content-between">
                  <div className="text-white">
                    {locale.t("validate_email_resend")}
                  </div>
                  <div>
                    <Button
                      disabled={countDown !== 0}
                      loading={loadingResend}
                      className="btn-primary-new-iux"
                      onClick={() => onResend()}
                    >
                      {countDown !== 0
                        ? `${countDown}s`
                        : locale.t("click_send")}
                    </Button>
                  </div>
                </WarningBox>
              )}
              {error && (
                <div className="d-flex m-2 justify-content-center">
                  <p style={{ color: "red" }}>{error}</p>
                </div>
              )}
              <Button
                htmlType="submit"
                style={{ textTransform: "uppercase" }}
                loading={props?.user?.loading}
                className="w-100 btn-primary-new-iux mt-4"
                block
              >
                {locale.t("login_button_text")}
              </Button>

              <p style={{ color: "gray" }} className="mt-4">
                {locale.t("login_lable_bottom")}
              </p>
            </Form>
          )}
        </div>
        <FooterAuthPage locale={locale} />
      </section>
    </>
  );
};
// }

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};
export default connect(mapStateToProps, {
  authentication,
  getUser,
  getBank,
  LogoutAuth,
  getUserv2,
})(LoginPage);
