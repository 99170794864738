import React, { useMemo, useState } from "react";
import { Alert, Button, Form, Input } from "antd";
import { post } from "../../helper/request";
import FooterAuthPage from "../../components/footer-landing-page/FooterAuth";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { renderApiDomain } from "../../helper/renderApiDomain";
import ImportantZa from "../../components/important-note/important-za";
import useLocationStorage from "../../helper/useLocationStorage";
import NavbarAuth from "../../components/navbar/NavbarAuth";

const locale = require("react-redux-i18n").I18n;

export const Times = styled.div`
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;

  border-radius: 4px;
  display: flex;
`;

export const ForgotPasswordForm = (props) => {
  // class ForgotPasswordForm extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       email: "",
  //       visible: false,
  //       message: "",
  //       type: "",
  //       loading: false,
  //     };
  //   }
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({
    visible: false,
    type: "error",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onSubmit = (value) => {
    setLoading(true);
    if (value.email) {
      post(renderApiDomain() + "forgot-password", {
        email: value.email,
      })
        .then((res) => {
          form.resetFields();
          setAlert({
            visible: true,
            type: "success",
            message:
              "Thank you! An email containing a link to resetting your password has been sent. Please check your email and follow the instructions to restore password.",
          });
          setLoading(false);
        })

        .catch((e) => {
          setLoading(false);
          setAlert({
            visible: true,
            type: "error",
            message: e.response.data.error,
          });
        });
    } else {
      setLoading(false);
      setAlert({
        visible: true,
        type: "error",
        message: "Please enter Email",
      });
    }
  };

  const onInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setEmail(value);
  };

  const [location] = useLocationStorage();

  return (
    <div className="bg-black">
      <NavbarAuth />
      <>
        <div className="container">
          <div className="text-center text-white">
            {locale.t("login_top_1")}&nbsp;
            <span className="font-bold-iux">
              {locale.t("login_top_2")}&nbsp;
            </span>
            {locale.t("login_top_3")}
          </div>
          {/* if you have to check ImportantZa component please change condition location !== 'ZA'  */}
          {location === "ZA" && <ImportantZa />}
        </div>
      </>
      <section className="w-100 d-flex flex-column align-content-center justify-content-center bg-black">
        <div className="text-center rectangle-card rectangle-card-login ml-auto mr-auto mt-4 mb-4 position-relative">
          <Times
            className="font-bold-iux position-absolute"
            onClick={() =>
              props.history.push(`/${localStorage.getItem("locale")}`)
            }
          >
            <CloseOutlined style={{ color: "#A0A0A0" }} />
          </Times>
          <div className="logo-register">
            <Link
              to={`/${localStorage.getItem("locale")}`}
              style={{ padding: 0, overflowY: "auto" }}
            >
              <img
                alt="IUX"
                className="registerlogo border-logo"
                src={
                  process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"
                }
              />
            </Link>
          </div>
          {alert.visible ? (
            <Alert
              className="m-3"
              message={alert.message}
              type={alert.type}
              showIcon
            />
          ) : null}

          <Form form={form} onFinish={onSubmit}>
            <h5
              className="text-center text-white fw-700 fs-24"
              style={{
                padding: "10px 0px 0px",
              }}
            >
              {locale.t("login_your_email")}
            </h5>
            <p
              className="text-center text-gray fs-14"
              style={{
                padding: "10px 0px 0px",

                fontWeight: 400,
              }}
            >
              {locale.t("forgot_pass_title")}
            </p>
            <div className="d-flex justify-content-center mt-4 mb-4">
              <Form.Item
                onChange={onInputChange}
                className="w-100 login"
                // label={locale.t("register_email")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: locale.t("dashboardWallet.input_error"),
                  },
                ]}
              >
                <Input
                  style={{ height: "42px" }}
                  className="w-100 login"
                  placeholder={locale.t("register_email")}
                  type="email"
                  // value={this.state.email}
                />
              </Form.Item>
              {/* <Input
                  className="w-100 login"
                  placeholder={locale.t("login_your_email")}
                  defaultValue={this.state.email}
                  name="email"
                  value={this.state.email}
                /> */}
            </div>
            {/* <div className="d-flex mt-4 justify-content-center">
              <p style={{ color: "red" }}>{this.state.error}</p>
            </div> */}
            <Button
              htmlType="submit"
              shape="round"
              loading={loading}
              className="mb-4 w-100 btn-primary-new-iux"
              type="primary"
              block
              // onClick={this.onSubmit}
            >
              {locale.t("login_button_text")}
            </Button>
          </Form>
        </div>
        <FooterAuthPage locale={locale} />
      </section>
    </div>
  );
};

export default ForgotPasswordForm;
