import { Col, message, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postAlogateway = ({ amount_thb, account_trade_id, ipaddress }) => {
  return post(`${renderApiDomain() + "deposit/alogateway"}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    ipaddress: ipaddress?.toString()?.replace("\n", ""),
  });
};

const Alogateway = (props) => {
  let urlParams = new URLSearchParams(window.location.search);
  let isCoupon = urlParams?.get("coupon");
  const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  const { paymentId } = props;
  const lang = localStorage.getItem("locale");
  const dispatch = useDispatch();
  const refPerfect = useRef(null);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    apiversion: "",
    version: "",
    merchant_account: "",
    merchant_order: "",
    merchant_product_desc: "",
    first_name: "",
    last_name: "",
    address1: "",
    city: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
    amount: 0,
    currency: "",
    bankcode: "",
    ipaddress: "",
    return_url: "",
    server_return_url: "",
    host: "",
    control: "",
  });

  useEffect(() => {
    onFinish();
  }, [selectIp]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      let ivid = sessionStorage.getItem("ivid");
      const payload = {
        account_trade_id: props.account.id,
        amount_thb: +props.amount,
        ipaddress: selectIp,
        deposit_coupon_id: isCoupon,
        ivid,
      };
      const { data } = await postAlogateway(payload);

      if (data) {
        setLoading(true);
        // setPaymentData({
        //   apiversion: data.data.apiversion,
        //   version:  data.data.version,
        //   merchant_account:  data.data.merchant_account,
        //   merchant_order:  data.data.merchant_order,
        //   merchant_product_desc:  data.data.merchant_product_desc,
        //   first_name:  data.data.first_name,
        //   last_name:  data.data.last_name,
        //   address1:  data.data.address1,
        //   city:  data.data.city,
        //   zip_code:  data.data.zip_code,
        //   country:  data.data.country,
        //   phone:  data.data.phone,
        //   email:  data.data.email,
        //   amount: data.data.amount,
        //   currency:  data.data.currency,
        //   bankcode:  data.data.bankcode,
        //   ipaddress:  data.data.ipaddress,
        //   return_url:  data.data.return_url,
        //   server_return_url:  data.data.server_return_url,
        //   host:  data.data.host,
        //   control:  data.data.control,
        // });
        await Promise.all([
          setPaymentData({
            apiversion: data.data.apiversion,
            version: data.data.version,
            merchant_account: data.data.merchant_account,
            merchant_order: data.data.merchant_order,
            merchant_product_desc: data.data.merchant_product_desc,
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            address1: data.data.address1,
            city: data.data.city,
            zip_code: data.data.zip_code,
            country: data.data.country,
            phone: data.data.phone,
            email: data.data.email,
            amount: data.data.amount,
            currency: data.data.currency,
            bankcode: data.data.bankcode,
            ipaddress: data.data.ipaddress,
            return_url: data.data.return_url,
            server_return_url: data.data.server_return_url,
            host: data.data.host,
            control: data.data.control,
          }),
        ]).then(() => {
          refPerfect.current.click();
        });
      }
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <form method="POST" action={paymentData.host}>
        <p>
          <input
            type="hidden"
            name="apiversion"
            value={paymentData.apiversion}
          />
          <input type="hidden" name="version" value={paymentData.version} />
          <input
            type="hidden"
            name="merchant_account"
            value={paymentData.merchant_account}
          />
          <input
            type="hidden"
            name="merchant_order"
            value={paymentData.merchant_order}
          />
          <input
            type="hidden"
            name="merchant_product_desc"
            value={paymentData.merchant_product_desc}
          />
          <input
            type="hidden"
            name="first_name"
            value={paymentData.first_name}
          />
          <input type="hidden" name="last_name" value={paymentData.last_name} />
          <input type="hidden" name="address1" value={paymentData.address1} />
          <input type="hidden" name="city" value={paymentData.city} />
          <input type="hidden" name="zip_code" value={paymentData.zip_code} />
          <input type="hidden" name="country" value={paymentData.country} />
          <input type="hidden" name="phone" value={paymentData.phone} />
          <input type="hidden" name="email" value={paymentData.email} />
          <input type="hidden" name="amount" value={paymentData.amount} />
          <input type="hidden" name="currency" value={paymentData.currency} />
          <input type="hidden" name="bankcode" value={paymentData.bankcode} />
          <input type="hidden" name="ipaddress" value={paymentData.ipaddress} />
          <input
            type="hidden"
            name="return_url"
            value={paymentData.return_url}
          />
          <input
            type="hidden"
            name="server_return_url"
            value={paymentData.server_return_url}
          />
          <input type="hidden" name="control" value={paymentData.control} />
          <input
            ref={refPerfect}
            type="submit"
            name="Submit"
            value="Submit"
            hidden
          />
        </p>
      </form>
    </Spin>
  );
};

export default Alogateway;
