// validationUtils.js

/**
 * สร้างข้อความแจ้งเตือน validation
 */
export const formatValidationMessage = (pattern, documentType, I18n) => {
  // Remove ^ and $ from pattern
  const cleanPattern = pattern.replace(/^\^|\$$/g, "");

  // Function to analyze one part of OR pattern
  const analyzePart = (part) => {
    const info = {
      isDigitsOnly: part.includes("\\d") || part.includes("[0-9]"),
      isAlphanumeric: part.includes("[A-Za-z0-9]") || part.includes("\\w"),
      hasLetterPrefix: part.startsWith("[A-Z]") || part.startsWith("[A-Za-z]"),
      length: null,
      lengthRange: null,
    };

    // Get length information
    const lengthMatch = part.match(/\{(\d+)(?:,(\d+))?\}/);
    if (lengthMatch) {
      const min = parseInt(lengthMatch[1]);
      const max = lengthMatch[2] ? parseInt(lengthMatch[2]) : min;
      info.lengthRange = [min, max];
    }

    return info;
  };

  // Cases for sequential patterns
  const sequentialPatterns = {
    // [A-Z]{1}\d{7}[A-Z]{1}
    upperDigitUpper: /^\[A-Z\]\{1\}\\d\{7\}\[A-Z\]\{1\}$/,
    // [A-Z]{1}[0-9]{10}
    upperDigits: /^\[A-Z\]\{1\}(?:\\d|\[0-9\])\{(\d+)\}$/,
    // [A-Za-z]{1,2}\d{7}
    letterRangeDigits: /^\[A-Za-z\]\{(\d+),?(\d+)?\}\\d\{7\}$/,
    // M[A-Z]{1,2}\d{6}
    mUpperRangeDigits: /^M\[A-Z\]\{(\d+),?(\d+)?\}\\d\{6\}$/,
  };

  // Check for sequential patterns
  for (const [type, regex] of Object.entries(sequentialPatterns)) {
    const match = cleanPattern.match(regex);
    if (match) {
      let message = "";
      switch (type) {
        case "upperDigitUpper":
          message = I18n.t("documentType.validation_global.sequentialFormat", {
            documentType: I18n.t(`documentType.${documentType}`),
            format: [
              I18n.t("documentType.validation_global.upperCase"),
              I18n.t("documentType.validation_global.numbersMultiple", {
                count: 7,
              }),
              I18n.t("documentType.validation_global.upperCase"),
            ].join(I18n.t("documentType.validation_global.followed")),
            total: 9,
          });
          break;

        case "upperDigits":
          const digitCount = parseInt(match[1]);
          message = I18n.t("documentType.validation_global.sequentialFormat", {
            documentType: I18n.t(`documentType.${documentType}`),
            format: [
              I18n.t("documentType.validation_global.upperCase"),
              I18n.t("documentType.validation_global.numbersMultiple", {
                count: digitCount,
              }),
            ].join(I18n.t("documentType.validation_global.followed")),
            total: digitCount + 1,
          });
          break;

        case "letterRangeDigits":
          const minLetters = parseInt(match[1]);
          const maxLetters = match[2] ? parseInt(match[2]) : minLetters;
          const letterDesc =
            minLetters === maxLetters
              ? I18n.t("documentType.validation_global.letters", {
                  count: minLetters,
                })
              : I18n.t("documentType.validation_global.lettersRange", {
                  min: minLetters,
                  max: maxLetters,
                });
          message = I18n.t("documentType.validation_global.sequentialFormat", {
            documentType: I18n.t(`documentType.${documentType}`),
            format: [
              letterDesc,
              I18n.t("documentType.validation_global.numbersMultiple", {
                count: 7,
              }),
            ].join(I18n.t("documentType.validation_global.followed")),
            total: maxLetters + 7,
          });
          break;

        case "mUpperRangeDigits":
          const minUpper = parseInt(match[1]);
          const maxUpper = match[2] ? parseInt(match[2]) : minUpper;
          const upperDesc =
            minUpper === maxUpper
              ? I18n.t("documentType.validation_global.upperCaseMultiple", {
                  count: minUpper,
                })
              : I18n.t("documentType.validation_global.upperCaseRange", {
                  min: minUpper,
                  max: maxUpper,
                });
          message = I18n.t("documentType.validation_global.sequentialFormat", {
            documentType: I18n.t(`documentType.${documentType}`),
            format: [
              I18n.t("documentType.validation_global.letterM"),
              upperDesc,
              I18n.t("documentType.validation_global.numbersMultiple", {
                count: 6,
              }),
            ].join(I18n.t("documentType.validation_global.followed")),
            total: 1 + maxUpper + 6,
          });
          break;
      }
      return message;
    }
  }

  // Check for OR patterns
  if (cleanPattern.includes("|")) {
    const parts = cleanPattern.split("|");
    const conditions = parts.map(analyzePart);
    const messages = conditions.map((condition) => {
      let message = "";

      if (condition.lengthRange) {
        const [min, max] = condition.lengthRange;
        if (min === max) {
          message = I18n.t("documentType.validation_global.exactLength", {
            length: min,
            documentType: I18n.t(`documentType.${documentType}`),
          });
        } else {
          message = I18n.t("documentType.validation_global.lengthRange", {
            min,
            max,
            documentType: I18n.t(`documentType.${documentType}`),
          });
        }
      }

      if (condition.isDigitsOnly) {
        message += ` (${I18n.t("documentType.validation_global.numbersOnly")})`;
      } else if (condition.isAlphanumeric) {
        message += ` (${I18n.t(
          "documentType.validation_global.alphanumeric"
        )})`;
      }

      return message;
    });

    return messages.join(`${I18n.t("documentType.validation_global.or")}`);
  }

  // Simple pattern
  const analysis = analyzePart(cleanPattern);
  let message = "";

  if (analysis.lengthRange) {
    const [min, max] = analysis.lengthRange;
    if (min === max) {
      message = I18n.t("documentType.validation_global.exactLength", {
        length: min,
        documentType: I18n.t(`documentType.${documentType}`),
      });
    } else {
      message = I18n.t("documentType.validation_global.lengthRange", {
        min,
        max,
        documentType: I18n.t(`documentType.${documentType}`),
      });
    }
  }

  if (analysis.isDigitsOnly) {
    message += ` (${I18n.t("documentType.validation_global.numbersOnly")})`;
  } else if (analysis.isAlphanumeric) {
    message += ` (${I18n.t("documentType.validation_global.alphanumeric")})`;
  }

  return (
    message ||
    I18n.t("documentType.validation_global.required", {
      documentType: I18n.t(`documentType.${documentType}`),
    })
  );
};

/**
 * สร้าง validation rules สำหรับ antd Form
 */
export const createValidationRules = (document, I18n) => {
  if (!document || !document.regex_pattern) {
    return [
      {
        required: true,
        message: I18n.t("documentType.validation_global.required", {
          documentType: I18n.t(`documentType.${document.document_type}`),
        }),
      },
    ];
  }

  const rules = [
    {
      required: true,
      message: I18n.t("documentType.validation_global.required", {
        documentType: I18n.t(`documentType.${document.document_type}`),
      }),
    },
    {
      pattern: new RegExp(document.regex_pattern.replace(/\\\\/g, "\\")),
      message: formatValidationMessage(
        document.regex_pattern,
        document.document_type,
        I18n
      ),
    },
  ];

  return rules;
};
