export const languageSupport = [
  {
    lang: "en",
    img: "gb",
    name: "English",
  },
  {
    lang: "es",
    img: "es",
    name: "Español",
  },
  {
    lang: "id",
    img: "id",
    name: "Bahasa Indonesia",
  },
  // {
  //   lang: "ms",
  //   img: "my",
  //   name: "Bahasa melayu",
  // },
  {
    lang: "th",
    img: "th",
    name: "ภาษาไทย",
  },
  {
    lang: "vi",
    img: "vn",
    name: "Tiếng Việt",
  },
  {
    lang: "zh",
    img: "cn",
    name: "简体中文",
  },
];
