import { message } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { setModalBonus } from "../../../../actions";
import { useHistory } from "react-router-dom";
import CreateAccount from "../../Account/CreateAccount";
import { ClipLoader } from "react-spinners";
import bonusCredit from "../../../../helper/bonusCredit";
const locale = require("react-redux-i18n").I18n;
const CHART_SIZE = { width: 82, height: 82 };
function Bonus() {
  const user = useSelector((state) => state.user);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  const isDetailStandart = accountTypeList?.find(
    (e) => e.type === "real" && e.name === "StandardBonus"
  );
  const chartOptions30 = {
    chart: { sparkline: { enabled: true } },
    colors: ["#1CB894"],

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#D9D9D9"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: 10,
            fontWeight: 700,
            color: user.bonus_balance_15 === 0 ? "#999" : "#1e1e1e",
            formatter: function (val) {
              return val === 0
                ? locale.t("received")
                : `$${
                    Math.floor(((val * bonusData[0]?.maximum) / 100) * 100) /
                    100
                  }`;
            },
          },
        },
      },
    },
  };
  const chartOptions15 = {
    chart: { sparkline: { enabled: true } },
    colors: ["#1CB894"],

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#D9D9D9"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: 10,
            fontWeight: 700,
            color: user.bonus_balance_15 === 0 ? "#999" : "#1e1e1e",
            formatter: function (val) {
              return val === 0
                ? locale.t("received")
                : `$${
                    Math.floor(((val * bonusData[1]?.maximum) / 100) * 100) /
                    100
                  }`;
            },
          },
        },
      },
    },
  };
  const onClickBonus = () => {
    if (
      user?.bonus_config?.bonus_countries?.find(
        (e) => e === user?.profile?.country_id
      )
    ) {
      if (
        user.bookBankVerify.status === "accepted" &&
        user.userVerify.status === "accepted"
      ) {
        dispatch(setModalBonus(true));
      } else {
        if (user.userVerify.status !== "accepted") {
          message.error(locale.t("open_account.error_er"));
          history.push(
            `/${localStorage.getItem("locale")}/wallet/account/Standard`
          );
        } else {
          message.error(locale.t("open_account.error_bank"));
          history.push(
            `/${localStorage.getItem("locale")}/wallet/fund/withdraw`
          );
        }
      }
    } else {
      history.push(`/${localStorage.getItem("locale")}/wallet/receivebonus`);
    }
  };

  const onCloseCreateAccount = () => {
    setModalCreateVisible(false);
  };

  const [bonusData, setBonusData] = useState([]);

  useEffect(() => {
    const fetchBonus = async () => {
      const bonusData = await bonusCredit();
      setBonusData(bonusData);
    };
    fetchBonus();
  }, []);

  if (!bonusData.length) {
    return "loading...";
  }

  return (
    <div>
      {user?.profile?.country_id ? (
        <div className="row ">
          {user?.bonus_config?.bonus_countries?.find(
            (e) => e === user?.profile?.country_id
          ) && (
            <div
              className={`col-12 ${
                isDetailStandart.acive > 0 ? "col-md-6" : ""
              }`}
            >
              <div className="bg-swap-status p-3">
                <h6 className="fw-700">
                  30 USD {locale.t("no_deposit_bonus")}{" "}
                </h6>
                <div className="d-flex mt-4">
                  <div>
                    <img
                      src="/assets/images/promotions/bonus30.svg"
                      alt="bonus30"
                    />
                  </div>
                  <div className="ml-3">
                    {locale.t("bonus_30_detail")}
                    <div className="mt-2">
                      <ul
                        style={{ paddingInlineStart: "20px" }}
                        className="fs-12"
                      >
                        <li>{locale.t("maximum_withdraw")} 150 USD</li>
                        <li>Spread {locale.t("starts")} 0.2 pips</li>
                        <li>Leverage {locale.t("up_to")} 3000</li>
                        <li>Swap-Free status</li>
                        <li>{locale.t("commission_free")}</li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
                <div className="d-flex justify-content-center justify-content-md-end my-3">
                  <button
                    id="get_30_bonus_button"
                    className="btn-primary-new-iux"
                    onClick={() => onClickBonus()}
                    disabled={
                      user.accountTradeBonusId.isRecived !== null ||
                      user?.accounts?.filter((e) => e.type === "welcome")
                        .length > 0
                    }
                  >
                    {user.accountTradeBonusId.isRecived === null &&
                    user?.accounts?.filter((e) => e.type === "welcome")
                      .length === 0
                      ? locale.t("menuWallet.getBonus")
                      : locale.t("received")}

                    {/* {locale.t("received")} */}
                    {/* Get $30 Bonus */}
                  </button>
                </div>
              </div>
            </div>
          )}
          {isDetailStandart.acive > 0 && (
            <div className="col-12 col-md-6 mt-3 mt-sm-0">
              <div className="bg-swap-status p-3">
                <h6 className="fw-700">
                  {bonusData[0]?.maximum + bonusData[1]?.maximum || "-"}{" "}
                  USD&nbsp; {locale.t("deposit_bonus")}
                </h6>
                <div className="d-flex mt-4">
                  <div>
                    <ReactApexCharts
                      type="radialBar"
                      series={[
                        (user.bonus_balance_30 * 100) / bonusData[0]?.maximum,
                      ]}
                      options={chartOptions30}
                      {...CHART_SIZE}
                    />
                    <div className="mt-2 fs-12 text-lightgray text-center">
                      {locale.t("dashboardWallet.bonus")}{" "}
                      {bonusData[0]?.bonus_pct}%
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="text-black">
                      {bonusData[0]?.bonus_pct || "-"}%{" "}
                      {locale.t("deposit_bonus")}
                    </p>
                    <br />
                    <span className="fs-12 text-lightgray-iux fw-300">
                      {locale
                        .t("deposit_bonus_35")
                        .replace("{{value1}}", bonusData[0]?.bonus_pct || "-")
                        .replace("{{value2}}", bonusData[0]?.maximum) || "-"}
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <div>
                    <ReactApexCharts
                      type="radialBar"
                      series={[
                        (Math.floor(user.bonus_balance_15) * 100) /
                          bonusData[1]?.maximum,
                      ]}
                      options={chartOptions15}
                      {...CHART_SIZE}
                    />
                    <div className="mt-2 fs-12 text-lightgray text-center">
                      {locale.t("dashboardWallet.bonus")}{" "}
                      {bonusData[1]?.bonus_pct}%
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="text-black">
                      {bonusData[1]?.bonus_pct || "-"}%{" "}
                      {locale.t("deposit_bonus")}
                    </p>
                    <br />
                    <span className="fs-12 text-lightgray-iux fw-300">
                      {locale
                        .t("deposit_bonus_25")
                        .replace("{{value1}}", bonusData[1]?.bonus_pct || "-")
                        .replace("{{value2}}", bonusData[1]?.maximum) || "-"}
                    </span>
                  </div>
                </div>
                <div className="mt-4">
                  <span className=" text-lightgray">
                    {locale.t("bonus_condition")}:{" "}
                  </span>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li className=" text-lightgray">
                      {locale.t("bonus_condition_detail")}
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-center justify-content-md-end ">
                  <button
                    className="btn-primary-new-iux"
                    onClick={() => setModalCreateVisible(true)}
                  >
                    Open Standard+ Account
                    {/* {locale.t("open_an_account")} */}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
            style={{ height: 500 }}
          >
            <ClipLoader
              color="#36d7b7"
              size={72}
              cssOverride={{ borderWidth: 8 }}
            />
            <p className="mt-5 text-lightgray-iux">
              {locale.t("loadingtitle")}
            </p>
          </div>
        </>
      )}
      <CreateAccount
        isOpen={modalCreateVisible}
        onClose={onCloseCreateAccount}
        isType={"trade-real"}
        isAccountType={isDetailStandart?.display}
      />
    </div>
  );
}

export default Bonus;
