import { ConfigProvider, Drawer, Dropdown, Space } from "antd";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import "../../assets/css/navbarAuth.css";
import { languageSupport } from "../../constants/language";
import { Logo } from "@vt/vt-ui";

function NavbarAuth() {
  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [drawerLangMobile, setDrawerLangMobile] = useState(false);

  const handleChangLanguageDesk = (newLang) => {
    // console.log(newLang, "newLang");
    const oldLang = localStorage.getItem("locale");
    localStorage.setItem("locale", newLang);
    const newPath = history?.location?.pathname.replace(oldLang, newLang);
    // console.log(newPath, "newPath");
    setDropdownVisible(false);
    history?.push(newPath);
    window.location?.reload();
  };

  const handleChangLanguage = async (newLang) => {
    const oldLang = localStorage.getItem("locale");
    localStorage.setItem("locale", newLang);
    const newPath = history.location.pathname.replace(oldLang, newLang);
    history.push(newPath);

    window.location.reload();
  };

  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const onChildrenDrawerClose = () => {
    setDrawerLangMobile(false);
  };

  const showChildrenDrawer = () => {
    setDrawerLangMobile(true);
  };

  const menu = (
    <div
      className="bg-white text-black d-flex flex-column auth-nav-lang"
      style={{ borderRadius: "12px", gap: "10px" }}
    >
      {languageSupport.map((item, index) => (
        <div
          key={index + item.lang}
          className="cursor-pointer d-flex align-items-center auth-nav-lang-list"
          style={{ fontSize: "16px", gap: "10px" }}
          onClick={() => handleChangLanguageDesk(item.lang)}
        >
          <div>{item.name}</div>
        </div>
      ))}
    </div>
  );
  return (
    <div className="bg-black">
      <div
        className="container d-flex align-items-center justify-content-between px-lg-0 px-3"
        style={{ padding: "17px 0px 17px 0px" }}
      >
        <div>
          <Link
            to={`/${localStorage.getItem("locale")}`}
            style={{ padding: 0, overflowY: "auto" }}
            className="cursor-pointer"
          >
            <Logo variant="withoutBg" size="lg" />
          </Link>
        </div>
        <div className="d-none d-xl-block">
          <Dropdown placement="bottomRight" overlay={menu} trigger={["click"]}>
            <div
              onClick={(e) => {
                e.preventDefault();
                handleDropdownClick();
              }}
              className="cursor-pointer mx-3 text-white"
            >
              <Space>
                <div className="header-menu-icons cursor-pointer">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99984 0.666504C12.0499 0.666504 15.3332 3.94975 15.3332 7.99984C15.3332 12.0499 12.0499 15.3332 7.99984 15.3332C3.94975 15.3332 0.666504 12.0499 0.666504 7.99984C0.666504 3.94975 3.94975 0.666504 7.99984 0.666504ZM2.0436 7.27144C2.23796 7.5202 2.77838 7.83849 3.60534 8.09818C3.93096 8.20043 4.28757 8.29032 4.67021 8.36665C4.66774 8.24509 4.6665 8.12279 4.6665 7.99984C4.6665 5.78141 5.07021 3.77804 5.76954 2.42804C3.77592 3.22678 2.31069 5.0645 2.0436 7.27144ZM4.75259 9.74039C3.68206 9.55431 2.76413 9.27269 2.06848 8.91036C2.39208 11.036 3.83102 12.795 5.76954 13.5716C5.25895 12.586 4.90595 11.252 4.75259 9.74039ZM6.11911 9.91806C6.71718 9.97193 7.34709 9.99984 7.99984 9.99984C8.64205 9.99984 9.2748 9.96344 9.88336 9.89593C9.58264 12.2943 8.75117 13.9998 7.99984 13.9998C7.25082 13.9998 6.42215 12.3048 6.11911 9.91806ZM9.9907 8.53618C9.35766 8.61816 8.6852 8.6665 7.99984 8.6665C7.30369 8.6665 6.63544 8.63203 6.01005 8.56665C6.00331 8.38021 5.99984 8.19115 5.99984 7.99984C5.99984 4.65583 7.06224 1.99984 7.99984 1.99984C8.93744 1.99984 9.99984 4.65583 9.99984 7.99984C9.99984 8.18073 9.99673 8.35962 9.9907 8.53618ZM11.2527 9.68443C11.1024 11.2191 10.747 12.5738 10.2301 13.5716C12.2029 12.7813 13.6582 10.9736 13.9473 8.79745C13.2405 9.16507 12.3096 9.47248 11.2527 9.68443ZM13.9522 7.23992C13.6385 7.59205 12.649 8.01033 11.3307 8.30259C11.3323 8.20215 11.3332 8.10122 11.3332 7.99984C11.3332 5.78141 10.9295 3.77804 10.2301 2.42804C12.2143 3.22298 13.675 5.04701 13.9522 7.23992Z"
                      fill="white"
                    />
                  </svg>
                  &nbsp;
                  {localStorage.getItem("locale")?.toUpperCase() || "JA"}
                </div>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: dropdownVisible ? "scaleY(1)" : "scaleY(-1)",
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.862 11.1382L13.8048 10.1954L8.00005 4.39064L2.19531 10.1954L3.13812 11.1382L8.00005 6.27625L12.862 11.1382Z"
                    fill="white"
                  />
                </svg>
              </Space>{" "}
            </div>
          </Dropdown>
        </div>
        <div
          onClick={(e) => {
            e.preventDefault();
            showChildrenDrawer();
          }}
          className="mx-3 text-white d-xl-none"
        >
          <Space>
            <div className="header-menu-icons d-flex align-items-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.99984 0.666504C12.0499 0.666504 15.3332 3.94975 15.3332 7.99984C15.3332 12.0499 12.0499 15.3332 7.99984 15.3332C3.94975 15.3332 0.666504 12.0499 0.666504 7.99984C0.666504 3.94975 3.94975 0.666504 7.99984 0.666504ZM2.0436 7.27144C2.23796 7.5202 2.77838 7.83849 3.60534 8.09818C3.93096 8.20043 4.28757 8.29032 4.67021 8.36665C4.66774 8.24509 4.6665 8.12279 4.6665 7.99984C4.6665 5.78141 5.07021 3.77804 5.76954 2.42804C3.77592 3.22678 2.31069 5.0645 2.0436 7.27144ZM4.75259 9.74039C3.68206 9.55431 2.76413 9.27269 2.06848 8.91036C2.39208 11.036 3.83102 12.795 5.76954 13.5716C5.25895 12.586 4.90595 11.252 4.75259 9.74039ZM6.11911 9.91806C6.71718 9.97193 7.34709 9.99984 7.99984 9.99984C8.64205 9.99984 9.2748 9.96344 9.88336 9.89593C9.58264 12.2943 8.75117 13.9998 7.99984 13.9998C7.25082 13.9998 6.42215 12.3048 6.11911 9.91806ZM9.9907 8.53618C9.35766 8.61816 8.6852 8.6665 7.99984 8.6665C7.30369 8.6665 6.63544 8.63203 6.01005 8.56665C6.00331 8.38021 5.99984 8.19115 5.99984 7.99984C5.99984 4.65583 7.06224 1.99984 7.99984 1.99984C8.93744 1.99984 9.99984 4.65583 9.99984 7.99984C9.99984 8.18073 9.99673 8.35962 9.9907 8.53618ZM11.2527 9.68443C11.1024 11.2191 10.747 12.5738 10.2301 13.5716C12.2029 12.7813 13.6582 10.9736 13.9473 8.79745C13.2405 9.16507 12.3096 9.47248 11.2527 9.68443ZM13.9522 7.23992C13.6385 7.59205 12.649 8.01033 11.3307 8.30259C11.3323 8.20215 11.3332 8.10122 11.3332 7.99984C11.3332 5.78141 10.9295 3.77804 10.2301 2.42804C12.2143 3.22298 13.675 5.04701 13.9522 7.23992Z"
                  fill="white"
                />
              </svg>
              &nbsp;
              {localStorage.getItem("locale")?.toUpperCase() || "JA"}
            </div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: dropdownVisible ? "scaleY(1)" : "scaleY(-1)",
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.862 11.1382L13.8048 10.1954L8.00005 4.39064L2.19531 10.1954L3.13812 11.1382L8.00005 6.27625L12.862 11.1382Z"
                fill="white"
              />
            </svg>
          </Space>{" "}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Drawer: {
                padding: 0,
                paddingLG: 0,
              },
            },
          }}
        >
          <Drawer
            placement="right"
            closable={false}
            onClose={onChildrenDrawerClose}
            visible={drawerLangMobile}
            className="p-0"
          >
            <>
              <div
                className="d-flex justify-content-between"
                style={{ padding: "16px" }}
              >
                <div
                  className="text-white"
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: 800,
                  }}
                >
                  Change Language
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ order: "2" }}
                  onClick={onChildrenDrawerClose}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  padding: "24px",
                }}
              >
                {languageSupport.map((i, index) => (
                  <div key={index}>
                    <Link
                      className="text-white"
                      to="#"
                      onClick={() => handleChangLanguage(i.lang)}
                    >
                      <div
                        className="d-flex align-items-center text-main-white"
                        style={{ fontSize: "16px", gap: "10px" }}
                      >
                        <img
                          src={`/assets/images/icons/flags/${i.lang}.svg`}
                          width={16}
                          height={16}
                          alt={i.lang}
                        />
                        <div className="text-lang">{i.name}</div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          </Drawer>
        </ConfigProvider>
      </div>
    </div>
  );
}

export default NavbarAuth;
