import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { get, post } from "../../../../helper/request";
import { message } from "antd";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import { updateModalCampaign } from "../../../../actions";

const ItemSelect = styled.div`
  box-shadow: 0 0 7px #80808033;
  padding: 12px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s;
  height: 100%;
  &:hover {
    background: #1f73b7;
    color: #fff;
  }
`;
export const postLinkPayment = ({
  amount_thb,
  account_trade_id,
  payment_key,
  deposit_coupon_id,
}) => {
  return post(`${renderApiDomain() + `deposit/${payment_key}`}`, {
    amount_thb: amount_thb,
    account_trade_id: account_trade_id,
    deposit_coupon_id: deposit_coupon_id,
  });
};

const LinkPayment = ({
  payment_key,
  amount,
  account,
  rate,
  paymentSelected,
  onBackStep,
}) => {
  // const selectIp = useSelector(({ user: { ipAddress } }) => ipAddress);
  let urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  let isCoupon = urlParams?.get("coupon");
  const refLinkPayment = useRef(null);
  const [loading, setLoading] = useState(false);
  const [linkPay, setLinkPay] = useState(null);

  useEffect(() => {
    onFinish();
  }, [account]);
  const onFinish = async (el) => {
    try {
      setLoading(true);
      let ivid = sessionStorage.getItem("ivid");
      const payload = {
        account_trade_id: account?.id,
        amount_thb: +amount,
        payment_key: payment_key,
        deposit_coupon_id: isCoupon || urlParams?.get("coupon"),
        ivid,
      };
      console.log(payload);
      const { data } = await postLinkPayment(payload);

      if (data) {
        setLoading(true);

        await Promise.all([setLinkPay(data.data.link)]).then(() => {
          refLinkPayment.current.click();
        });
      }
    } catch (error) {
      if (error.response.data.code === 4004 && isCoupon) {
        dispatch(updateModalCampaign(true));
      } else {
        message.error(error.response.data.error);
      }

      // message.error(error.response.data.error);
      onBackStep("error");
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      <a ref={refLinkPayment} href={linkPay} hidden>
        LinkPayment
      </a>
    </Spin>
  );
};

export default LinkPayment;
