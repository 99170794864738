import React, { useEffect, useState } from "react";
import { Form, Result, Select, Button, message, Steps } from "antd";
import { connect, useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { post, puts } from "../../../../helper/request";
import {
  getCountries,
  getUserVerifyv2,
  updateVerifierUser,
} from "../../../../actions";
import CryptoJS from "crypto-js";
import PhoneDialog from "../../../setting/dialog";
import styled from "styled-components";
import {
  getProvinceList,
  getUserValidation,
  getUserAttempt,
  putUserAttempt,
} from "../../../../api/verifyuser";
import Microblink from "./Microblink";
import { ClipLoader } from "react-spinners";
import FormVerify from "./FormVerify";
import RejectVerify from "./RejectVerify";

import VerifyUserManual from "./VerifyUserManual";
import { RemovePrefix } from "../../../../helper/removePrefixName";
import OTPVerify from "./OTPVerify";
import { useHistory } from "react-router-dom";
import InprogressVerify from "./InprogressVerify";
import SelectDocementType from "./SelectDocementType";
import { getuser } from "../../../../firebase";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
import VerificationGuide from "./VerificationGuide";

const locale = require("react-redux-i18n").I18n;
// const fs = require("fs");
const { Option } = Select;
const { Step } = Steps;
const OtpButton = styled(Button)`
  background-color: ${(props) =>
    props.countDown !== 0 ? "#d0d0d02e " : "#9dd3f400"} !important;
  border: ${(props) =>
    props.countDown !== 0
      ? "1px solid #d0d0d02e "
      : "1px solid #185f91"} !important;
  color: ${(props) => (props.countDown !== 0 ? "gray" : "#185f91")} !important;
  border-radius: 4px;
  height: 40px;
`;

function VerifyUser(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [file, setFile] = useState(null);
  const [fileIndia, setFileIndia] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState({
    phone: false,
    bank: false,
  });
  const [state, setState] = useState({
    phone: false,
    email: false,
  });
  const [hoverInformation, setHoverInformation] = useState(false);
  const [numberPhone, setNumberPhone] = useState(undefined);
  const [prefixPhone, setPrefixPhone] = useState(undefined);
  const [verifyFrom, setVerifyFrom] = useState(undefined);
  const [bankList, setBankList] = useState(undefined);
  const [provinceList, setProvinceList] = useState(undefined);
  const [dataValidation, setDataValidation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isReason, setIsReason] = useState(null);
  const [loadingUser, setloadingUser] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [checked, setChecked] = useState(1);
  const [form] = Form.useForm();
  const lang = localStorage.getItem("locale");
  const user = useSelector((state) => state.user);
  const userIsVerify = useSelector((state) => state.user.userVerify);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [isValidationData, setIsValidationData] = useState({});
  const [showKyc, setShowKyc] = useState(false);
  const [currentTabs, setCurrentTabs] = useState(0);
  const [dataKyc, setDataKyc] = useState();
  const [dataAttempt, setDataAttempt] = useState(null);
  const [encryptSign, setEncryptSign] = useState("");
  const [showManual, setShowManual] = useState(false);
  const [startVerification, setStartVerification] = useState(false);
  const profile = user?.profile;
  const checkTypeVerify = user?.countries?.find(
    (e) => e.id === profile?.country_id
  );
  // console.log(profileCountry, "profileCountry");
  useEffect(() => {
    // if (props.user.userVerify.status === "rejected") {
    //   setCurrentTabs(1);
    //   addDataForm();
    // }
    if (props.user.userVerify.status === "rejected") {
      if (currentTabs === 2) {
        setCurrentTabs(0);
      }
      fetchAttempt();
      setIsReject(true);
    }
    if (props.user.userVerify.status === "processed") {
      setCurrentTabs(1);
      addDataForm();
    }
  }, [props.user.userVerify]);
  useEffect(() => {
    setShowManual(false);
  }, [props.user.userVerify]);
  const addDataForm = async () => {
    await form.setFieldsValue({ id_card_number: userIsVerify?.id_card_number });
    await form.setFieldsValue({ first_name: userIsVerify?.first_name });
    await form.setFieldsValue({ last_name: userIsVerify?.last_name });
    // moment(userIsVerify?.birth_date).format("YYYY-MM-DD")
    await form.setFieldsValue({
      birthday: moment(userIsVerify?.birth_date),
    });
  };
  useEffect(() => {
    // const unlisten = history.listen((location, action) => {
    //   // This function will be called whenever the path changes
    //   console.log("Path changed to", location.pathname);
    // });

    // The cleanup function to unsubscribe when the component unmounts
    return () => {
      if (currentTabs === 2) {
        // dispatch(
        //   updateVerifierUser({
        //     status: "accepted",
        //     userVerifyInfo: null,
        //   })
        // );
        dispatch(getUserVerifyv2());
      } else {
        dispatch(getCountries());
        dispatch(getUserVerifyv2());
      }
    };
  }, []);
  useEffect(() => {
    const initial = user.otp_to;

    if (user !== "") {
      setState({ phone: initial === "phone", email: initial === "email" });
    }
  }, [user]);

  useEffect(() => {
    const country = profile?.country_id;
    if (country > 0) {
      setloadingUser(false);
      setPrefixPhone(user?.profileCountry?.prefix_phone);
    }
  }, [profile]);
  useEffect(() => {
    // initmicroblink();

    fetchProvinList();
    // fetchBankList();
    fetchUserValidation();
    fetchAttempt();

    // dispatch(getUserVerifyv2());
  }, []);

  const fetchProvinList = async () => {
    try {
      const { data } = await getProvinceList();
      setProvinceList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickRadio = () => {
    if (profile.phone_number === null) {
      setIsModalVisible({ bank: false, phone: true });
      setVerifyFrom(true);
    }
  };
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleVerifyUser = async (type) => {
    if (type === "otp" && checkTypeVerify?.verify_type === "auto_otp") {
      setShowOTP(true);
    } else {
      try {
        setLoading(true);
        setShowOTP(false);
        const values = await form.validateFields();
        const str = `${userIsVerify?.document_type}:${
          userIsVerify.id_card_number
        }:${userIsVerify.document_type}:${userIsVerify.id_card_number}:${
          userIsVerify.first_name
        }:${userIsVerify.last_name}:${moment(userIsVerify.birth_date).format(
          "YYYY-MM-DD"
        )}:${userIsVerify.expired_date || ""}:${profileCountry?.short_name}`;
        let base64Encryp = CryptoJS.HmacSHA256(str, await getuser()).toString(
          CryptoJS.enc.Hex
        );
        // setLoading(true);

        if (
          checkTypeVerify?.verify_type === "auto_jumio" ||
          checkTypeVerify?.verify_type === "auto_jumio_otp"
        ) {
          const payloadJumio = {
            id_card: dataKyc?.file || "",
            address: values.address,
            phone: `${user?.profileCountry?.prefix_phone}${
              values.phone.charAt(0) === "0"
                ? values.phone.substring(1)
                : values.phone
            }`,
            [provinceList?.data?.length > 0 ? "province_id" : "province"]:
              values.province,
            // province_id: values.province,
            zipcode: values.post,
            career_id: values.other ? values.other : values.job,
            card_data: {
              user_document_type: userIsVerify?.document_type,
              user_document_number: form.getFieldValue(
                `id_card_number_${isValidationData.document_type}`
              )
                ? form.getFieldValue(
                    `id_card_number_${isValidationData.document_type}`
                  )
                : userIsVerify?.id_card_number,
              document_type:
                dataKyc?.documentType || userIsVerify?.document_type,
              document_number:
                dataKyc?.id_card_number || userIsVerify?.id_card_number,
              first_name:
                RemovePrefix(dataKyc?.first_name || userIsVerify?.first_name) ||
                userIsVerify?.first_name,
              last_name: dataKyc?.last_name || userIsVerify?.last_name,
              birth_date:
                dataKyc?.birth_date === "0-0-0"
                  ? "0-0-0"
                  : moment(
                      dataKyc?.birth_date || userIsVerify?.birth_date
                    ).format("YYYY-MM-DD"),
              expired_date:
                dataKyc?.dateOfExpiry === "0-0-0"
                  ? "0-0-0"
                  : dataKyc?.dateOfExpiry
                  ? moment(dataKyc?.dateOfExpiry).format("YYYY-MM-DD")
                  : "",
              country:
                user?.countries?.find((e) => e.id === userIsVerify?.country_id)
                  ?.short_name || "",
              sign: encryptSign || base64Encryp || "",
            },
          };

          // console.log(payloadJumio, "payloadJumio");
          puts(renderApiDomain() + "user/verify-user", payloadJumio)
            .then((res) => {
              setCurrentTabs(2);
              setLoading(false);
            })
            .catch((e) => {
              setLoading(false);

              message.error(e?.response?.data?.error);
            });
        } else {
          const payload = {
            id_card: dataKyc?.file,
            address: values?.address,
            phone: `${user?.profileCountry?.prefix_phone}${
              values.phone.charAt(0) === "0"
                ? values.phone.substring(1)
                : values.phone
            }`,
            [provinceList?.data?.length > 0 ? "province_id" : "province"]:
              values.province,
            // province_id: values.province,
            zipcode: values.post,
            career_id: values.other ? values.other : values.job,
            card_data: {
              user_document_type: isValidationData.document_type,
              user_document_number: form.getFieldValue(
                `id_card_number_${isValidationData.document_type}`
              ),
              document_type: dataKyc.documentType,
              document_number: dataKyc.id_card_number,
              first_name: RemovePrefix(dataKyc.first_name),
              last_name: dataKyc.last_name,
              birth_date:
                dataKyc?.birth_date === "0-0-0"
                  ? "0-0-0"
                  : moment(dataKyc?.birth_date).format("YYYY-MM-DD"),
              expired_date:
                dataKyc?.dateOfExpiry === "0-0-0"
                  ? "0-0-0"
                  : moment(dataKyc?.dateOfExpiry).format("YYYY-MM-DD"),
              country: dataKyc.countryName.toLowerCase(),
              sign: encryptSign,
            },
          };
          post(renderApiDomain() + "user/verify-user", payload)
            .then((res) => {
              setCurrentTabs(2);
              setLoading(false);
            })
            .catch((e) => {
              setLoading(false);

              message.error(e?.response?.data?.error);
            });
        }
      } catch (errorInfo) {
        message.error("Please complete the information.");
        console.log(errorInfo);
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    if (profile.phone_number === null) {
      setState({
        phone: false,
        email: true,
      });
    }
    setIsModalVisible({
      phone: false,
      bank: false,
    });
  };
  useEffect(() => {
    if (isReason || userIsVerify?.status === "rejected") {
      // console.log(isReason);
      setIsReject(true);
    }
  }, [isReason, userIsVerify?.status]);
  const onKycSuccess = async (isData) => {
    try {
      setIsLoading(true);
      const olddata = {
        user_document_type: isValidationData.document_type,
        user_document_number: form.getFieldValue(
          `id_card_number_${isValidationData.document_type}`
        ),
        document_type: isData.documentType,
        document_number: isData.id_card_number,
        first_name: RemovePrefix(isData.first_name),
        last_name: isData.last_name,
        birth_date:
          isData?.birth_date === "0-0-0"
            ? "0-0-0"
            : moment(isData?.birth_date).format("YYYY-MM-DD"),
        expired_date:
          isData?.dateOfExpiry === "0-0-0"
            ? "0-0-0"
            : moment(isData?.dateOfExpiry).format("YYYY-MM-DD"),
        country: isData?.countryName?.toLowerCase(),
      };
      const str = `${olddata.user_document_type}:${olddata.user_document_number}:${olddata.document_type}:${olddata.document_number}:${olddata.first_name}:${olddata.last_name}:${olddata.birth_date}:${olddata.expired_date}:${olddata.country}`;
      let base64Encryp = CryptoJS.HmacSHA256(str, await getuser()).toString(
        CryptoJS.enc.Hex
      );
      const payload = {
        user_document_type: isValidationData.document_type,
        user_document_number: form.getFieldValue(
          `id_card_number_${isValidationData.document_type}`
        ),
        document_type: isData.documentType,
        document_number: isData.id_card_number || "0000000",
        first_name: RemovePrefix(isData.first_name),
        last_name: isData.last_name,
        birth_date:
          isData?.birth_date === "0-0-0"
            ? "0-0-0"
            : moment(isData?.birth_date).format("YYYY-MM-DD"),
        expired_date:
          isData?.dateOfExpiry === "0-0-0"
            ? "0-0-0"
            : moment(isData?.dateOfExpiry).format("YYYY-MM-DD"),
        country: isData.countryName.toLowerCase(),
        sign: base64Encryp,
      };
      const { data } = await putUserAttempt(payload);
      setDataKyc(isData);
      setEncryptSign(base64Encryp);
      await form.setFieldsValue({ first_name: isData.first_name });
      await form.setFieldsValue({ last_name: isData.last_name });
      await form.setFieldsValue({ id_card_number: isData.id_card_number });
      await form.setFieldsValue({ birthday: moment(isData?.birth_date) });
      setIsSuccess(true);
      // let isAttemp = dataAttempt;
      // isAttemp.attempt = data.data.attempt;
      // setDataAttempt(isAttemp);
      // setCurrentTabs(1);
      setIsLoading(false);
    } catch (error) {
      setIsReason(error?.response?.data?.data);
      console.log(error);
      setIsLoading(false);
    }
    // const publicKey =
  };
  const isOTPError = () => {
    setShowOTP(false);
  };
  const onChangeDoc = (e) => {
    setIsValidationData(e);
  };
  const onNextStepVerify = () => {
    setShowKyc(true);
  };
  const onJunioSubmit = () => {
    // setIsReason(null);
    // setIsReject(false);
    // dispatch(getUserVerifyv2());
  };

  function verifyUserForm() {
    if (profile) {
      return (
        <>
          {/* <RSAOAEPEncoding /> */}

          {isLoading ? (
            <div
              className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
              style={{ height: 500 }}
            >
              <ClipLoader
                color="#36d7b7"
                size={72}
                cssOverride={{ borderWidth: 8 }}
              />
              <p className="mt-5 text-lightgray-iux">
                {locale.t("loadingtitle")}
              </p>
            </div>
          ) : (
            <>
              {dataAttempt?.status !== "normal" && dataAttempt?.attempt > 3 ? (
                <div>
                  {/* <BlockVerify
                    blockType={dataAttempt?.status}
                    isTimeCountdown={dataAttempt?.updatedAt}
                    dataReason={dataAttempt}
                    onChangeManualVerify={onChangeManualVerify}
                  /> */}

                  <RejectVerify
                    checkTypeVerify={checkTypeVerify}
                    onChangeAutoVerify={onChangeAutoVerify}
                    dataAttempt={dataAttempt}
                    isReason={isReason}
                    onChangeManualVerify={onChangeManualVerify}
                  />
                </div>
              ) : (
                <div className={`${!showOTP ? "d-block" : "d-none"}`}>
                  <Form
                    style={{ padding: "28px" }}
                    layout="vertical"
                    name="verify_user"
                    form={form}
                    initialValues={{
                      email: profile?.email,
                      first_name: profile?.first_name,
                      last_name: profile?.last_name,
                      prefix: user?.profileCountry?.prefix_phone,
                      phone: "",
                      document_type:
                        dataValidation[0]?.document_type ||
                        userIsVerify?.document_type,
                      ["id_card_number_" + isValidationData?.document_type ||
                      userIsVerify?.document_type]: "",
                      country: user?.countries?.find(
                        (e) => e.id === profile.country_id
                      )?.name,
                    }}
                    className="user"
                  >
                    {currentTabs === 0 && (
                      <>
                        {
                          (!checkTypeVerify?.verify_type.includes("jumio") &&
                            isReject) ||
                          (checkTypeVerify?.verify_type.includes("jumio") &&
                            props.user.userVerify.status !== "initial" &&
                            isReject) ? (
                            <>
                              {/* isReject {userIsVerify?.status} */}
                              <RejectVerify
                                checkTypeVerify={checkTypeVerify}
                                onChangeAutoVerify={onChangeAutoVerify}
                                dataAttempt={dataAttempt}
                                isReason={isReason}
                                onChangeManualVerify={onChangeManualVerify}
                              />
                            </>
                          ) : isSuccess ? (
                            <div className="d-flex justify-content-center my-5 flex-column align-items-center">
                              <div className="fs-16">
                                {locale.t("verifySuccess")}
                              </div>
                              <div className="iux-scan success d-flex justify-content-center mt-4">
                                <img
                                  src="/assets/images/verify/identitysuccess.svg"
                                  alt="success"
                                />
                              </div>
                              <div className="fs-16 mt-4 text-lightgray">
                                {locale.t("verifySuccessdetial")}
                              </div>
                              <Button
                                className="mt-4 btn-primary-new-iux"
                                type="primary"
                                onClick={() => setCurrentTabs(1)}
                              >
                                <p className=""> {locale.t("continue")}</p>
                              </Button>
                            </div>
                          ) : null
                          // <Microblink onKycSuccess={onKycSuccess} />
                        }
                        {(checkTypeVerify?.verify_type === "auto_jumio" ||
                          checkTypeVerify?.verify_type === "auto_jumio_otp") &&
                        (!isReject || userIsVerify?.status === "initial") ? (
                          <>
                            <VerificationGuide
                              startVerification={startVerification}
                              onJunioSubmit={onJunioSubmit}
                              onChangeManualVerify={onChangeManualVerify}
                              onChangeAutoVerify={onChangeAutoVerify}
                              userIsVerify={userIsVerify}
                            />
                          </>
                        ) : (
                          <>
                            {!showKyc && !isReject && (
                              <SelectDocementType
                                dataValidation={dataValidation}
                                form={form}
                                isValidationData={isValidationData}
                                onChangeDoc={onChangeDoc}
                                onNextStepVerify={onNextStepVerify}
                              />
                            )}
                            {showKyc && !isReject && !isSuccess && (
                              <Microblink onKycSuccess={onKycSuccess} />
                            )}
                          </>
                        )}
                      </>
                    )}
                    {currentTabs === 1 && (
                      <>
                        {/* <button onClick={() => setCurrentTabs(0)}>
                          asdasdasd
                        </button> */}
                        <FormVerify
                          isValidationData={isValidationData}
                          form={form}
                          provinceList={provinceList}
                          handleVerifyUser={handleVerifyUser}
                          prefixPhone={prefixPhone}
                          onClickRadio={onClickRadio}
                          setNumberPhone={setNumberPhone}
                          setState={setState}
                          state={state}
                          loading={loading}
                          checkTypeVerify={checkTypeVerify}
                          userIsVerify={userIsVerify}
                        />
                      </>
                    )}
                  </Form>
                  {currentTabs === 2 && (
                    <div className="d-flex justify-content-center my-5 flex-column align-items-center">
                      <div className="fs-16"> {locale.t("verifySuccess")}</div>
                      <div className="iux-scan success d-flex justify-content-center mt-4">
                        <img
                          src="/assets/images/verify/success.svg"
                          alt="success"
                        />
                      </div>
                      <div className="fs-16 mt-4 text-lightgray">
                        {locale.t("verifySuccessdetial")}
                      </div>

                      <Button
                        className="mt-4 btn-primary-new-iux"
                        type="primary"
                        onClick={() => {
                          // dispatch(
                          //   updateVerifierUser({
                          //     status: "accepted",
                          //     userVerifyInfo: null,
                          //   })
                          // );
                          dispatch(getUserVerifyv2);
                          history.push(
                            `/${localStorage.getItem(
                              "locale"
                            )}/wallet/account/openaccount`
                          );
                        }}
                      >
                        <p>{locale.t("dashboardWallet.createAccount")}</p>
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {showOTP && (
            <OTPVerify
              verifyFrom={verifyFrom}
              numberPhone={form.getFieldValue("phone")}
              profile={profile}
              prefixPhone={prefixPhone}
              handleVerifyUser={handleVerifyUser}
              form={form}
              isOTPError={isOTPError}
            />
          )}
          <PhoneDialog
            verifyFrom={verifyFrom}
            numberPhone={numberPhone}
            profile={profile}
            prefixPhone={prefixPhone}
            locale={locale}
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
          />
        </>
      );
    }
  }
  const fetchUserValidation = async () => {
    try {
      const { data } = await getUserValidation();
      setDataValidation(data?.data);
      if (
        checkTypeVerify?.verify_type !== "auto_jumio" &&
        checkTypeVerify?.verify_type !== "auto_jumio_otp"
      ) {
        setIsValidationData(data?.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAttempt = async () => {
    try {
      setLoadingProfile(true);
      const { data } = await getUserAttempt();

      if (data?.data?.is_passed === 1) {
        if (moment(data?.data.updatedAt).isBefore(user.userVerify.actionAt)) {
          let reasonAdmin = user?.userVerify;
          let dataAdminReject = data.data;
          dataAdminReject.reason_data.text = reasonAdmin.reason_data.text;
          dataAdminReject.reason_data.value = reasonAdmin.reason_data.value;

          setIsReason(dataAdminReject);
        } else if (data?.data?.reason_data?.text) {
          setIsReason(data.data);
        } else {
          let dataAdminReject = data.data;
          // dataAdminReject.attempt = 0;
          dataAdminReject.reason_data.text = locale.t("adminRejectTopic");
          dataAdminReject.reason_data.value = locale.t("adminRejectReason");
          setIsReason(dataAdminReject);
        }
      } else {
        if (
          user?.userVerify?.actionAt &&
          moment(data?.data?.updatedAt).isBefore(user?.userVerify?.actionAt)
        ) {
          let reasonAdmin = user?.userVerify;
          let dataAdminReject = data.data;
          dataAdminReject.reason_data.text = reasonAdmin.reason_data.text;
          dataAdminReject.reason_data.value = reasonAdmin.reason_data.value;

          setIsReason(dataAdminReject);
        } else if (data?.data?.reason_data?.text) {
          setIsReason(data.data);
        } else {
          if (data?.data?.attempt > 0) {
            let dataAdminReject = data.data;
            // dataAdminReject.attempt = 0;
            dataAdminReject.reason_data.text = locale.t("adminRejectTopic");
            dataAdminReject.reason_data.value = locale.t("adminRejectReason");
            setIsReason(dataAdminReject);
          }
        }
      }

      setDataAttempt(data?.data);
      setLoadingProfile(false);
    } catch (error) {
      console.log(error);
      setLoadingProfile(false);
    }
  };
  const onChangeManualVerify = () => {
    setStartVerification(false);
    setIsReject(false);
    setShowManual(true);
    if (checkTypeVerify.verify_type === "auto_otp") {
      checkTypeVerify.verify_type = "manual_otp";
    } else {
      checkTypeVerify.verify_type = "manual";
    }
  };
  const onChangeAutoVerify = () => {
    setStartVerification(true);
    setIsReject(null);
    setShowManual(false);
    setShowKyc(false);
    form.resetFields();
  };

  const steps = [
    {
      title: locale.t("step1"),
      content: "First-content",
    },
    {
      title: locale.t("step2"),
      content: "Second-content",
    },
    {
      title: locale.t("step3"),
      content: "Last-content",
    },
  ];
  return (
    <>
      {/* <RSAOAEPEncoding /> */}
      {props.user.userVerify.status === "not_verify" ||
      props.user.userVerify.status === "initial" ||
      props.user.userVerify.status === "processed" ||
      props.user.userVerify.status === "rejected" ? (
        <>
          {loadingProfile || loadingUser ? (
            <div
              className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
              style={{ height: 500 }}
            >
              <ClipLoader
                color="#36d7b7"
                size={72}
                cssOverride={{ borderWidth: 8 }}
              />
            </div>
          ) : (
            <>
              <h2 className="font-weight-bold px-4 fs-24 fs-xs-16">
                {locale.t("kycTitle")}
              </h2>
              <div style={{ padding: "28px", overflow: "hidden" }}>
                <div className="stepper-wrapper">
                  {steps.map((e, i) => (
                    <div
                      className={`stepper-item ${
                        currentTabs >= i ? "completed" : ""
                      } ${i === 0 ? "cursor-pointer" : ""}`}
                      key={i}
                      onClick={() => {
                        if (i === 0) {
                          history.push(
                            `/${localStorage.getItem("locale")}/wallet/account`
                          );
                        }
                      }}
                    >
                      <div className="step-counter">{i + 1}</div>
                      <div
                        className={`step-name ${
                          currentTabs === i ? "active" : ""
                        }`}
                      >
                        {e.title}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <Jumio /> */}
              {/* <Jumio
                isValidationData={isValidationData}
                form={form}
                provinceList={provinceList}
                handleVerifyUser={handleVerifyUser}
                prefixPhone={prefixPhone}
                onClickRadio={onClickRadio}
                setNumberPhone={setNumberPhone}
                setState={setState}
                state={state}
                loading={loading}
                checkTypeVerify={checkTypeVerify}
              /> */}

              {showManual ||
              checkTypeVerify?.verify_type === "manual" ||
              checkTypeVerify?.verify_type === "manual_otp" ? (
                <>
                  {isReject && props.user.userVerify.status !== "initial" ? (
                    <>
                      {/* isReject {userIsVerify?.status} */}
                      <RejectVerify
                        checkTypeVerify={checkTypeVerify}
                        onChangeAutoVerify={onChangeAutoVerify}
                        dataAttempt={dataAttempt}
                        isReason={isReason}
                        onChangeManualVerify={onChangeManualVerify}
                      />
                    </>
                  ) : (
                    <VerifyUserManual
                      checkTypeVerify={checkTypeVerify}
                      setCurrentTabs={setCurrentTabs}
                    />
                  )}
                </>
              ) : checkTypeVerify?.verify_type === "auto" ||
                checkTypeVerify?.verify_type === "auto_jumio" ||
                checkTypeVerify?.verify_type === "auto_jumio_otp" ||
                checkTypeVerify?.verify_type === "auto_otp" ? (
                verifyUserForm()
              ) : (
                <VerifyUserManual
                  checkTypeVerify={checkTypeVerify}
                  setCurrentTabs={setCurrentTabs}
                />
              )}
            </>
          )}
        </>
      ) : props.user.userVerify.status === "pending" ? (
        <InprogressVerify des={locale.t("sendverifysuccess")} />
      ) : props.user.userVerify.status === "accepted" ? (
        <Result
          status="success"
          title="You passed identity verification."
          subTitle="Have enjoy with iUXmarkets."
        />
      ) : (
        <Result
          style={{ opacity: "0" }}
          status="success"
          title=""
          subTitle=""
        />
      )}
    </>
  );
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { updateVerifierUser })(VerifyUser);
