import React, { useEffect, useState } from "react";
import Inprogress from "./Jumio/Inprogress";
import ModalJumio from "./Jumio/ModalJumio";
import FormVerify from "./FormVerify";
import { postJumioCreate } from "../../../../api/verifyuser";
import { useDispatch } from "react-redux";
import { getUserVerifyv2 } from "../../../../actions";
import { ClipLoader } from "react-spinners";
const locale = require("react-redux-i18n").I18n;
function Jumio(props) {
  const dispatch = useDispatch();
  const { onJunioSubmit } = props;
  // const {
  //   isValidationData,
  //   provinceList,
  //   form,
  //   handleVerifyUser,
  //   prefixPhone,
  //   onClickRadio,
  //   setNumberPhone,
  //   setState,
  //   state,
  //   loading,
  //   checkTypeVerify,
  // } = props;
  const [isWebview, setIsWebview] = useState("");
  const [isStatus, setIsStatus] = useState(null);
  function receiveMessage(event) {
    if (typeof event?.data === "string") {
      try {
        var data = JSON?.parse(event?.data);
        // console.log("ID Verification Web was loaded in an iframe.");
        // console.log("auth-token:", data?.authorizationToken);
        // console.log("event-type:", data?.eventType);
        // console.log("date-time:", data?.dateTime);
        // console.log("workflow-execution-id:", data?.workflowExecutionId);
        // console.log("account-id:", data?.accountId);
        // console.log(
        //   "customer-internal-reference:",
        //   data?.customerInternalReference
        // );

        if (data?.payload?.value) {
          setIsStatus(data?.payload?.value);
        }

        if (data?.payload?.value === "success") {
          onJunioSubmit();
          // dispatch(getUserVerifyv2());
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  useEffect(() => {
    // Add event listener on mount
    window?.addEventListener("message", receiveMessage, false);

    // Cleanup function to remove event listener on unmount
    return () => {
      window?.removeEventListener("message", receiveMessage, false);
    };
  }, [isWebview]);
  useEffect(() => {
    fetchJumioCreate();
  }, []);

  const fetchJumioCreate = async () => {
    try {
      const { data } = await postJumioCreate();
      setIsWebview(data);
    } catch (error) {
      console.log(error);
    }
  };
  // window.addEventListener("message", receiveMessage, false);
  return (
    <div className="w-100" style={{ height: "100%", position: "relative" }}>
      <>
        {isStatus !== "success" && (
          <div>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "750px",
                display: isStatus === null ? "none" : "block",
              }}
            >
              <iframe
                src={isWebview?.web?.href}
                width="100%"
                height="750px"
                allow="camera;autoplay;fullscreen;clipboard-read;clipboard-write;accelerometer;gyroscope;magnetometer"
                allowfullscreen
                title="Jumio Verification Process"
                style={{
                  border: 0,
                  position: !isStatus ? "absolute" : "sticky",
                  zIndex: !isStatus ? -10 : 50,
                  // hidden: true,
                  // height: "100%",
                  // height: !isStatus ? "0%" : "unset",
                }}
              ></iframe>
            </div>

            {isStatus === null && (
              <div
                className="h-100 d-flex justify-content-center align-items-center flex-column my-5 "
                style={{ height: 500 }}
              >
                <ClipLoader
                  color="#36d7b7"
                  size={72}
                  cssOverride={{ borderWidth: 8 }}
                />
                <p className="mt-5 text-lightgray-iux">
                  {locale.t("loadingtitle")}
                </p>
              </div>
            )}
          </div>
        )}
        {isStatus === "success" && (
          <div>
            <Inprogress />
          </div>
        )}
      </>

      {/* <FormVerify
        isValidationData={isValidationData}
        form={form}
        provinceList={provinceList}
        handleVerifyUser={handleVerifyUser}
        prefixPhone={prefixPhone}
        onClickRadio={onClickRadio}
        setNumberPhone={setNumberPhone}
        setState={setState}
        state={state}
        loading={loading}
        checkTypeVerify={checkTypeVerify}
      /> */}
    </div>
  );
}

export default Jumio;
