import { Button } from "antd";
import React, { useEffect, useState } from "react";
import Jumio from "./Jumio";
import styles from "./verify.module.css";
const locale = require("react-redux-i18n").I18n;

function VerificationGuide({
  onChangeManualVerify,
  onChangeAutoVerify,
  onJunioSubmit,
  startVerification = false,
  userIsVerify,
}) {
  const [isStartVerify, setIsStartVerify] = useState(startVerification);

  useEffect(() => {
    return () => {
      setIsStartVerify(false);
    };
  }, []);

  return (
    <div className={styles.verificationContainer}>
      {isStartVerify || userIsVerify?.status === "initial" ? (
        <Jumio onJunioSubmit={onJunioSubmit} />
      ) : (
        <div className="d-flex justify-content-center my-5 flex-column align-items-center">
          <h1 className={styles.verificationTitle}>
            {locale.t("identityVerificationGuide")}
          </h1>
          <p className={styles.verificationDescription}>
            {locale.t("identityVerificationGuideDetail")}
          </p>
          <div
            className={`box-reason mtc-24 flex-column ${styles.verificationBox}`}
          >
            <div className={styles.verificationItem}>
              <span className={styles.verificationItemIcon}>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8064 4.36356C14.0658 4.62482 14.0643 5.04693 13.8031 5.30637L6.41848 12.6397C6.15851 12.8979 5.73893 12.8979 5.47896 12.6397L2.19691 9.38044C1.93565 9.121 1.93418 8.69889 2.19362 8.43764C2.45306 8.17638 2.87517 8.17491 3.13643 8.43435L5.94872 11.2271L12.8636 4.36028C13.1248 4.10084 13.5469 4.10231 13.8064 4.36356Z"
                    fill="#1E1E1E"
                  />
                </svg>
              </span>
              <p className={styles.verificationItemText}>
                {locale.t("identityVerificationGuideDetail1")}
              </p>
            </div>
            <div className={styles.verificationItem}>
              <span className={styles.verificationItemIcon}>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8064 4.36356C14.0658 4.62482 14.0643 5.04693 13.8031 5.30637L6.41848 12.6397C6.15851 12.8979 5.73893 12.8979 5.47896 12.6397L2.19691 9.38044C1.93565 9.121 1.93418 8.69889 2.19362 8.43764C2.45306 8.17638 2.87517 8.17491 3.13643 8.43435L5.94872 11.2271L12.8636 4.36028C13.1248 4.10084 13.5469 4.10231 13.8064 4.36356Z"
                    fill="#1E1E1E"
                  />
                </svg>
              </span>
              <p className={styles.verificationItemText}>
                {locale.t("identityVerificationGuideDetail2")}
              </p>
            </div>
            <div className={styles.verificationItem}>
              <span className={styles.verificationItemIcon}>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8064 4.36356C14.0658 4.62482 14.0643 5.04693 13.8031 5.30637L6.41848 12.6397C6.15851 12.8979 5.73893 12.8979 5.47896 12.6397L2.19691 9.38044C1.93565 9.121 1.93418 8.69889 2.19362 8.43764C2.45306 8.17638 2.87517 8.17491 3.13643 8.43435L5.94872 11.2271L12.8636 4.36028C13.1248 4.10084 13.5469 4.10231 13.8064 4.36356Z"
                    fill="#1E1E1E"
                  />
                </svg>
              </span>
              <p className={styles.verificationItemText}>
                {locale.t("identityVerificationGuideDetail3")}
              </p>
            </div>
          </div>
          <div className={styles.verificationActionContainer}>
            <Button
              className="btn-primary-new-iux"
              type="primary"
              onClick={() => {
                setIsStartVerify(true);
                onChangeAutoVerify();
              }}
            >
              {locale.t("autoverify")}
            </Button>
            <Button
              className="btn-outline-iux"
              type="primary"
              onClick={() => {
                setIsStartVerify(false);
                onChangeManualVerify();
              }}
            >
              {locale.t("manualverify")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VerificationGuide;
