import React, { useEffect, useState } from "react";
import { getIframeWebinar } from "../../../api/webinar";
import LoadingIux from "../../../components/loading";
import { useHistory } from "react-router-dom";
import ModalComingSoon from "./ModalComingSoon";

function Webinar() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showWebinar, setShowWebinar] = useState(false);
  const [linkIframe, setLinkIframe] = useState(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  useEffect(() => {
    fetchIframeLink();
  }, []);

  const onClose = () => {
    history.push(`/${localStorage.getItem("locale")}/wallet/`);
  };
  const fetchIframeLink = async () => {
    try {
      const { data } = await getIframeWebinar();
      if (data?.data?.iframe) {
        setLinkIframe(data?.data?.iframe);
        setIsLoading(false);
      } else {
        setShowComingSoon(true);
        setShowWebinar(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div style={{ height: "80vh" }}>
      {isLoading ? (
        <LoadingIux />
      ) : (
        <>
          {!showComingSoon ? (
            <div className="w-100">
              <>
                <div dangerouslySetInnerHTML={{ __html: linkIframe }} />
              </>
            </div>
          ) : (
            <div className="overflow-hidden">
              <ModalComingSoon isOpen={showComingSoon} onClose={onClose} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Webinar;
